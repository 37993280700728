import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";

const useAudio = (voiceID) => {
  const [audio, setAudio] = useState(new Audio(`/audio/${voiceID}.mp3`));
  const [playing, setPlaying] = useState(false);
  useEffect(() => {
    setPlaying(false);
    setAudio(new Audio(`/audio/${voiceID}.mp3`));
  }, [voiceID]);

  const toggle = () => setPlaying(!playing);

  useEffect(
    () => {
      playing ? audio.play() : audio.pause();
    },
    // eslint-disable-next-line
    [playing]
  );

  useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
    // eslint-disable-next-line
  }, []);

  return [playing, toggle];
};

const Player = ({ url }) => {
  const [playing, toggle] = useAudio(url);

  return (
    <div>
      {playing ? (
        <IconButton size="small" color="primary" onClick={toggle}>
          <VolumeUpIcon fontSize="small" />
        </IconButton>
      ) : (
        <IconButton size="small" color="primary" onClick={toggle}>
          <VolumeUpIcon fontSize="small" />
        </IconButton>
      )}
    </div>
  );
};

export default Player;
