import React, {useContext, useEffect, useRef, useState} from 'react';

import VideoStreamBtn from "./helpers/VideoStreamBtn.js";
import Stt from './helpers/Stt.js';
import ThreeDiv from '../threejs/ThreeDiv.js';
import WebCam from "./WebCam";
import RightBtnGroup from './helpers/RightBtnGroup.js'
import config from '../config'
import { AM } from "./helpers/chatUtils";
import { isScenarioCharacter, onScenarioHandler } from "../config/scenarios";
import { useDispatch, useSelector } from "react-redux";
import {onCompleteScenarioStep, selectScenario} from "../slices/scenario";
import AwsWebSocket from "./helpers/AwsWebSocket";
import {
  selectConfig, setRecognizedText,
} from "../slices/config";
import {EventsContext} from "../eventsQueue";
import {EVENT_PRIORITY, EVENT_TYPES} from "../constants/events";


const ALLOWED_EVENTS = [
  EVENT_TYPES.OBJECT_DETECTED,
  EVENT_TYPES.EXPRESSION_DETECTED,
]

function Content() {
  const dispatch = useDispatch();
  const { createEvent, pushToQueue, finishEvent, processingEvent } = useContext(EventsContext)
  const {
    sttTimeout,
    ttsText,
    neuralEngine,
    detectionOrigin,
  } = useSelector(selectConfig)
  const scenario = useSelector(selectScenario)
  const scenarioRef = useRef(null);
  const [turnVideo, setTurnVideo] = useState(false);
  const [ttsDuration, setTtsDuration] = useState(0);

  useEffect(() => {
    if (scenario) {
      scenarioRef.current = [...scenario]
    }
  }, [scenario]);


  useEffect(() => {
    if (processingEvent && ALLOWED_EVENTS.includes(processingEvent.type)) {
      const { type, data } = processingEvent
      onDetect(type, data.value)
    }
    // eslint-disable-next-line
  }, [processingEvent])

  const onDetect = (type, value) => {
    const { EXPRESSIONS_VALUES, OBJECTS_VALUES } = config.constants;

    const isScenario = isScenarioCharacter();
    finishEvent()
    if (isScenario) {
      const { stepId, scenarioText } = onScenarioHandler(scenarioRef.current, type, value);
      if (stepId) {
        dispatch(onCompleteScenarioStep(stepId));
      }

      if (scenarioText && (type === EVENT_TYPES.OBJECT_DETECTED || type === EVENT_TYPES.EXPRESSION_DETECTED)) {
        dispatch(setRecognizedText(AM(scenarioText.original)));
        const event = createEvent({
          type: EVENT_TYPES.TTS_PROCESSING,
          data: {
            value: scenarioText.original,
            speech: scenarioText.speech,
          },
          weight: EVENT_PRIORITY.HIGH,
        })
        pushToQueue(event)
      }
      return;
    }

    let replies = {};
    if (type === EVENT_TYPES.EXPRESSION_DETECTED) replies = EXPRESSIONS_VALUES;
    if (type === EVENT_TYPES.OBJECT_DETECTED) replies = OBJECTS_VALUES;
    if (!(Object.keys(replies).length && replies[value]?.length)) return;

    const text = replies[value][Math.floor(Math.random() * replies[value].length)];
    if (text) {
      const event = createEvent({
        type: EVENT_TYPES.TTS_PROCESSING,
        data: {
          value: text,
        },
        weight: EVENT_PRIORITY.HIGH,
      })
      pushToQueue(event)
    }
  };

  return (
    <div className="main-content">
      <div className="video-content" id="video-content">

        <ThreeDiv
          ttsText={ttsText}
          neuralEngine={neuralEngine}
          setTtsDuration={setTtsDuration}/>

        <WebCam
          isEnabled={turnVideo}
          faceDetectionOptions={{}}
          objectsDetectionOptions={{}}
          detectionOrigin={detectionOrigin}
        />

      </div>
      <div className="video-controll">
        <div className="video-controll-empty" />
        <div className="central-controll-block">
          <AwsWebSocket>
            <Stt
              sttTimeout={sttTimeout}
              ttsDuration={ttsDuration}
              setTtsDuration={setTtsDuration}
            />
          </AwsWebSocket>
          <VideoStreamBtn turnVideo={turnVideo} setTurnVideo={setTurnVideo}/>
        </div>
        <RightBtnGroup />
      </div>
    </div>
  );
}

export default Content;
