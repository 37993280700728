import React from 'react';
import { IconButton, Divider } from '@mui/material';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';

import styles from './MakeStyles.js';


function VideoStreamBtn({ turnVideo, setTurnVideo }) {
  const classes = styles.videoStreamStyles();


  return (
      <div className="tts">
        {turnVideo ? 
            <>
                <Divider orientation="vertical" flexItem />
                <IconButton
                    className={classes.iconButton}
                    onClick={() => setTurnVideo(!turnVideo)}
                    aria-label="replay"
                    component="span"
                    size="large">
                    <VideocamIcon fontSize='inherit' />
                </IconButton>
            </>
        : 
            <>
              <Divider orientation="vertical" flexItem />
                <IconButton
                    className={classes.redIconButton}
                    onClick={() => setTurnVideo(!turnVideo)}
                    aria-label="replay"
                    component="span"
                    size="large">
                    <VideocamOffIcon fontSize='inherit' />
                </IconButton>
            </> 
        }
      </div>
  );
}

export default VideoStreamBtn;
