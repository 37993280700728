import React, { useState } from "react";
import Fab from "@mui/material/Fab";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";

const ITEM_HEIGHT = 48;

const RefChanger = ({
  isEnabled,
  videoInputs,
  constraints,
  setConstraints,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (id) => {
    setAnchorEl(null);
    if (typeof id === "string") {
      if (constraints.video.deviceId !== id && id !== "") {
        setConstraints({ video: { width: 250, deviceId: id } });
        localStorage.setItem("videoInput", id);
      }
    }
  };

  return (
    <div className={`video-webcam-btn ${isEnabled ? "" : "disabled"}`}>
      <Fab
        size="small"
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <CameraswitchIcon />
      </Fab>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "30ch",
          },
        }}
      >
        {videoInputs.length === 0 ? (
          <MenuItem onClick={() => handleClose("")}>
            No available video devices
          </MenuItem>
        ) : (
          <div>
            {videoInputs.map((videoInputs) => (
              <MenuItem
                key={videoInputs.id}
                selected={constraints.video.deviceId === videoInputs.id}
                onClick={() => handleClose(videoInputs.id)}
              >
                {videoInputs.label}
              </MenuItem>
            ))}
          </div>
        )}
      </Menu>
    </div>
  );
};

export default RefChanger;
