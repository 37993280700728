import makeStyles from '@mui/styles/makeStyles';

const sttStyles = makeStyles(() => ({
    iconMinOn: {
        color: '#3f51b5',
        fontSize: '2rem',
        background: '#fafbfc',
        border: '1px solid #657cff1e',
    },
    iconMinOff: {
        color: '#F44336',
        fontSize: '2rem',
        background: '#fafbfc',
        border: '1px solid #657cff1e',
    },
}));

const inputStyles = makeStyles(() => ({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 300,
    },
    input: {
      marginLeft: 7,
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
}));

const videoStreamStyles = makeStyles(() => ({
    iconButton: {
        color: '#3f51b5',
        fontSize: '2rem',
        background: '#fafbfc',
        border: '1px solid #657cff1e',
    },
    redIconButton: {
        color: '#F44336',
        fontSize: '2rem',
        background: '#fafbfc',
        border: '1px solid #657cff1e',
    },
}));

const RightBtnGroup = makeStyles(() => ({
    iconButton: {
        color: '#5e5e5e',
        fontSize: '2rem',
        background: '#edeeee',
        height: 50,
        width: 50,
      },
      textButton: {
        color: '#5e5e5e',
        fontSize: '2rem',
        background: '#ffffff',
        border: '1px solid #657cff1e',
        borderRadius: 30,
        height: 50,
        width: 200,
    },
}));

const popStyle = makeStyles(() => ({
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: 10,
    },
}));

const sideStyle = makeStyles(() => ({
  formControl: {
    margin: 5,
    minWidth: 150,
    marginTop: 15
  },
  selectEmpty: {
    marginTop: 10,
  },
}));


const exportModules = {
  sttStyles,
  inputStyles,
  videoStreamStyles,
  RightBtnGroup,
  popStyle,
  sideStyle,
}

export default exportModules;
