import React, { useEffect } from "react";
import LoaderDiv from "./LoaderDiv";
import { useKeycloak } from "@react-keycloak/web";

const SignIn = () => {
  const { keycloak } = useKeycloak();

  useEffect(() => {
    keycloak.login({redirectUri: `${window.location.origin}/app/char`})
  }, []);

  return (
    <LoaderDiv />
  );
};

export default SignIn;
