import { createSlice } from '@reduxjs/toolkit'

const name = 'modals'

const initialState = {
  characterPromptModalState: {
    data: null,
    isOpen: false,
  },
};

export const modalsReducer = createSlice({
  name,
  initialState,
  reducers: {
    setCharacterPromptModalState: (state, action) => {
      state.characterPromptModalState = action.payload
    },
  },
});

export const {
  setCharacterPromptModalState,
} = modalsReducer.actions;

export const selectModalState = state => state[name]

// characterPromptModalState
export const selectCharacterPromptModalState = state => state[name].characterPromptModalState
export const selectIsOpenCharacterPromptModal = state => state[name].characterPromptModalState.isOpen

export default modalsReducer.reducer
