import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CHARACTER_PRESETS } from '../../../config/constants';
import { getSelectedCharacter } from "../../../config/sceneConfig";

import { Button, ButtonGroup, InputLabel, Modal, Input } from "@mui/material";

import {
  selectCharacterPromptModalState,
  setCharacterPromptModalState,
} from "../../../slices/modals";
import Box from "@mui/material/Box";
import { styles } from "./styles";
import { setCharacterPrompt } from "../../../slices/config";

const CharacterPromptModal = () => {
  const { isOpen, data } = useSelector(selectCharacterPromptModalState);
  const dispatch = useDispatch();
  const [value, setValue] = useState(data);

  useEffect(() => {
    if (data) {
      setValue(data);
    }
  }, [data]);

  const onSubmit = () => {
    dispatch(setCharacterPrompt(value));
    localStorage.setItem("promptset", value);
    handleClose();
  };

  const onReset = () => {
    dispatch(setCharacterPrompt(CHARACTER_PRESETS[getSelectedCharacter()]));
    localStorage.removeItem("promptset");
    handleClose();
  };

  const handleClose = () => {
    dispatch(
      setCharacterPromptModalState({
        data: null,
        isOpen: false,
      })
    );
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={styles.wrapper}>
        <Box sx={styles.content}>
          <InputLabel id="selector-label">Character prompt</InputLabel>
          <Input
            sx={styles.textArea}
            multiline={true}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <ButtonGroup
            variant="text"
            aria-label="outlined primary button group"
          >
            <Button color='primary' onClick={onSubmit}>Save</Button>
            <Button color='error' onClick={onReset}>Reset</Button>
          </ButtonGroup>
        </Box>
      </Box>
    </Modal>
  );
};

export default CharacterPromptModal;
