import React from 'react';
import ReactDOM from 'react-dom';
import Keycloak from 'keycloak-js';
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { Provider } from "react-redux";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { store } from "./store";
import { getUserInfo} from './http';


export const keycloak = new Keycloak({
    url: 'https://auth-stable.verbato.ai/auth',
    realm: 'verbato',
    clientId: 'verbato-ai',
});

const eventLogger = (event, error) => {
   //  console.log('onKeycloakEvent', event, error)
}

const tokenLogger = (tokens) => {
    // todo need to save token
    localStorage.setItem("access_token", tokens.token);
   getUserInfo(tokens.token)
}

ReactDOM.render(
  <React.StrictMode>
      <ReactKeycloakProvider
          authClient={keycloak}
          onEvent={eventLogger}
          onTokens={tokenLogger}
          initOptions={{
              onLoad: "check-sso"
          }}
      >
    <Provider store={store}>
      <App />
    </Provider>
    </ReactKeycloakProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
