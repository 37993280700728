import React from 'react'
import {Container, Tooltip, Typography} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import {useKeycloak} from '@react-keycloak/web';

const WithoutAccess = () => {
  const { keycloak } = useKeycloak()

  const doLogout = () => {
    let redirectLocalUri = window.location.href;
    redirectLocalUri = redirectLocalUri.split("/")[0] + "//" + redirectLocalUri.split("/")[2] + "/logout";

    try {
      keycloak.logout({redirectUri: redirectLocalUri})
    }catch(e){
      console.error("Something went wrong ! ", e)
    }
  }

  return <Container fixed style={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh'
  }}>
    <Tooltip
        style={{position: 'absolute', top: 15, right: 15, cursor: 'pointer'}}
        title={"Press to logout"}><LogoutIcon  onClick={() => doLogout()} /></Tooltip>
    <Typography variant={'h2'} >Welcome to Verbato</Typography>
    <Typography>
      Thank you for registering to access the beta testing resources please contact the administrator.
      support-admin@verbato.ai
    </Typography>
  </Container>
}

export default WithoutAccess
