import React, { useContext, useEffect, useRef } from "react";
import {SocketContext} from "../../socket";
import conf from "../../config";
import {useSelector} from "react-redux";
import {selectIsConnected} from "../../slices/socket";
import {EventsContext} from "../../eventsQueue";
import {EVENT_PRIORITY, EVENT_TYPES} from "../../constants/events";


const ServerDetection = (props) => {
  const {
    videoRef,
    isReady,
    faceDetectionOptions,
    objectsDetectionOptions,
    objectsTimeoutsRef,
    expressionSelectedRef,
    expressionTimeoutRef,
  } = props
  // eslint-disable-next-line
  const { EXPRESSIONS, OBJECTS, EXPRESSION, OBJECT } = conf.constants;
  const socket = useContext(SocketContext)
  const { createEvent, pushToQueue } = useContext(EventsContext)
  const isSocketConnected = useSelector(selectIsConnected)
  const frameCanvasRef = useRef(null);
  const acceptableExpressionDelay = faceDetectionOptions?.delay || 2 * 1000;
  const acceptableObjectsDelay = objectsDetectionOptions?.delay || 2 * 1000;
  const detectionRoutineRef = useRef();
  const acceptableExpressionsWhitelist = faceDetectionOptions?.whitelist || {
    [EXPRESSIONS.HAPPY]: 1,
    [EXPRESSIONS.SURPRISED]: 1
  };
  const acceptableObjectsWhitelist = objectsDetectionOptions?.whitelist || {
    [OBJECTS.BANANA]: 1,
    [OBJECTS.CELL_PHONE]: 1,
    [OBJECTS.BOOK]: 1,
    [OBJECTS.PERSON]: 1,
  };

  useEffect(() => {
    if (isSocketConnected) {
      listenSocket()
    }
    return () => {
      clearInterval(detectionRoutineRef.current)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!isSocketConnected) {
      listenSocket()
    }
    // eslint-disable-next-line
  }, [isSocketConnected]);

  useEffect(() => {
    if (!isReady) {
      clearInterval(detectionRoutineRef.current)
      return
    }
    onPlay()
    // eslint-disable-next-line
  }, [isReady]);

  const listenSocket = () => {
    try {
      socket.on('/frame-processing-result', processResult)
    } catch (e) {
      console.log('error in listenSocket', e)
    }
  }

  const onDetect = (type, value) => {
    pushToQueue(createEvent({
      type,
      data: {
        value,
      },
      weight: EVENT_PRIORITY.MEDIUM,
      // expireDate: Date.now() + 30 * 1000,
    }))
  }

  const processResult = (result) => {
    result.forEach(data => {
      const { type, value } = data
      if (type === 'OBJECT') {
        const sendDetected = (object) => {
          return () => {
            if (acceptableObjectsWhitelist[object] > 0) {
              onDetect(EVENT_TYPES.OBJECT_DETECTED, object);
              acceptableObjectsWhitelist[object] -= 1
            }
            objectsTimeoutsRef.current[object] = 0
          };
        };
        if (!objectsTimeoutsRef.current[value]) {
          objectsTimeoutsRef.current[value] = setTimeout(sendDetected(value), acceptableObjectsDelay);
        }
      }

      if (type === 'EXPRESSION') {
        if (value !== expressionSelectedRef.current) {
          expressionSelectedRef.current = value;
          clearTimeout(expressionTimeoutRef.current);
          expressionTimeoutRef.current = setTimeout(() => {
            if (expressionSelectedRef.current !== value) return null;
            if (acceptableExpressionsWhitelist[expressionSelectedRef.current] > 0) {
              onDetect(EVENT_TYPES.EXPRESSION_DETECTED, expressionSelectedRef.current);
              acceptableExpressionsWhitelist[expressionSelectedRef.current] -= 1
            }
          }, acceptableExpressionDelay)
        }
      }
    })
  }

  const onPlay = async () => {
    const videoEl = videoRef.current;
    if (!videoEl) return setTimeout(() => onPlay());
    if (videoEl.paused || videoEl.ended) return setTimeout(() => onPlay());

    detectionRoutineRef.current = setInterval(() => {
        socket.emit('/frame-processing', {
          frame: captureFrame(videoEl),
        })
    }, 1000);
  };

  const captureFrame = (videoEl) => {
    const canvas = frameCanvasRef.current

    canvas.width = videoEl.videoWidth;
    canvas.height = videoEl.videoHeight;
    canvas.getContext('2d').drawImage(videoEl, 0, 0, videoEl.videoWidth, videoEl.videoHeight, 0, 0, videoEl.videoWidth, videoEl.videoHeight);

    return canvas.toDataURL('image/jpeg', 0.7).replace(/^data:image\/[a-z]+;base64,/, "")
  }

  return (
    <canvas ref={frameCanvasRef} style={{ display: 'none' }} />
)
}

export default ServerDetection
