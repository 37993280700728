export const modalStyles = {
  wrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '600px',
    width: '100%',
    overflow: 'auto',
    backgroundColor: '#ffffff',
    outline: 'none',
  },
}
