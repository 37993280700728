export const ANNOTATION_PREFIX = '@>';
export const SCENARIO_PREFIX = '#>';

function isAnnotationMessage(input) {
 const regexp = new RegExp(`^${ANNOTATION_PREFIX}.*$`, 'gi');
 return regexp.test(input)
}

function createAnnotationMessage(input) {
 if (isAnnotationMessage(input)) return input;
 return `${ANNOTATION_PREFIX} ${input}`;
}

function getPureMessageFromAnnotation(input) {
  return input.replace(ANNOTATION_PREFIX, '')
}

function isScenarioMessage(input) {
 const regexp = new RegExp(`^${SCENARIO_PREFIX}.*$`, 'gi');
 return regexp.test(input)
}

function createScenarioMessage(input) {
 if (isAnnotationMessage(input)) return input;
 return `${SCENARIO_PREFIX} ${input}`;
}

function getPureMessageFromScenario(input) {
 return input.replace(SCENARIO_PREFIX, '')
}

export const isAM = isAnnotationMessage;
export const AM = createAnnotationMessage;
export const AMP = getPureMessageFromAnnotation;

export const isSM = isScenarioMessage;
export const SM = createScenarioMessage;
export const SMP = getPureMessageFromScenario;

export const isSimilar = (a, b) => {
 let equivalency = 0;
 const minLength = (a.length > b.length) ? b.length : a.length;
 const maxLength = (a.length < b.length) ? b.length : a.length;
 for (let i = 0; i < minLength; i++) {
  if(a[i] === b[i]) {
   equivalency++;
  }
 }


 const weight = equivalency / maxLength;
 return weight * 100;
};