import React, {useEffect, useRef, useState} from "react";
import BrowserDetection from "./Detection/BrowserDetection";
import {DETECTION_ORIGIN} from "../config/constants";
import ServerDetection from "./Detection/ServerDetection";
import RefChanger from "./helpers/RefChanger.js";

const WebCam = ({ isEnabled, faceDetectionOptions, objectsDetectionOptions, onDetect, detectionOrigin }) => {
  const videoRef = useRef(null);
  const streamRef = useRef(null);
  const [videoInputs, setVideoInputs] = useState([]);
  const [constraints, setConstraints] = useState({ video: { width: 250 } });
  const [isReady, setIsReady] = useState(false)
  const objectsTimeoutsRef = useRef({});
  const expressionTimeoutRef = useRef(0);
  const expressionSelectedRef = useRef(null);


  useEffect(() => {
    // Controlling video stream ref
    (async () => {
      if (isEnabled) {
        if (videoInputs.length > 0) {
          streamRef.current = await navigator.mediaDevices.getUserMedia(constraints);
          videoRef.current.srcObject = streamRef.current;
        } else {
          streamRef.current = await navigator.mediaDevices.getUserMedia(constraints);

          let devices = await navigator.mediaDevices.enumerateDevices();
          devices.forEach(function (device) {
            if (device.kind === "videoinput") {
              setVideoInputs((videoInputs) => [
                ...videoInputs,
                { label: device.label, id: device.deviceId },
              ]);
              videoInputs.push({ label: device.label, id: device.deviceId });
            }
          });

          videoRef.current.srcObject = streamRef.current;
        }
      } else {
        if (!streamRef.current) return;
        streamRef.current.getVideoTracks().forEach(function (track) {
          track.stop();
        });
        streamRef.current = null;
        setIsReady(false)
      }
    })();
    // eslint-disable-next-line
  }, [isEnabled]);

  useEffect(() => {
    // This block of code stopping the video when camera will be changed
    (async () => {
      if (!streamRef.current) return;
      streamRef.current.getVideoTracks().forEach(function (track) {
        track.stop();
      });
      streamRef.current = null;

      if (isEnabled) {
        streamRef.current = await navigator.mediaDevices.getUserMedia(
          constraints
        );
        videoRef.current.srcObject = streamRef.current;
      }
    })();
    // eslint-disable-next-line
  }, [constraints]);

  const renderDetectors = () => {
    if (!isEnabled) return null
    if (detectionOrigin === DETECTION_ORIGIN.SERVER) {
      return <ServerDetection
        onDetect={onDetect}
        videoRef={videoRef}
        isReady={isReady}
        objectsTimeoutsRef={objectsTimeoutsRef}
        expressionTimeoutRef={expressionTimeoutRef}
        expressionSelectedRef={expressionSelectedRef}
      />
    }
    return <BrowserDetection
      faceDetectionOptions={faceDetectionOptions}
      objectsDetectionOptions={objectsDetectionOptions}
      onDetect={onDetect}
      videoRef={videoRef}
      isReady={isReady}
      objectsTimeoutsRef={objectsTimeoutsRef}
      expressionTimeoutRef={expressionTimeoutRef}
      expressionSelectedRef={expressionSelectedRef}
    />
  }

  return (
    <>
      <div className={`video-placeholder ${isEnabled ? "" : "disabled"}`}>
        <div className="lds-roller"> 
          <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
        </div>
      </div>
      <video
        ref={videoRef}
        onLoadedMetadata={() => setIsReady(true)}
        className={`video-webcam ${isEnabled ? '' : 'disabled'}`}
        autoPlay
        muted
        playsInline
      />
      <RefChanger
        isEnabled={isEnabled}
        videoInputs={videoInputs}
        constraints={constraints}
        setConstraints={setConstraints}
      />
      {renderDetectors()}
    </>
  );
};

export default WebCam
