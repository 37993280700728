import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isConnected: false,
};

export const socketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    setIsConnected: (state, action) => {
      state.isConnected = action.payload
    },
  },
});

export const {
  setIsConnected,
} = socketSlice.actions;

export const setIsSocketConnected = (state) => (dispatch) => {
  dispatch(setIsConnected(state))
};

export const selectIsConnected = state => {
  return state.socket.isConnected
};

export default socketSlice.reducer
