export class SocketMsgQueue {
    _socket;
    _queue;
    _connectListener;
    constructor() {
      this._socket = null
      this._queue = []

      this._connectListener = () => {
        this._deliver()
      }
    }

    set socket(sock) {
      this._socket = sock

      sock.on('connect', this._connectListener)
    }

    get connected() {
      return !!this._socket && this._socket.connected
    }

    disconnect() {
      if(!this._socket)
        return

      this._socket.off('connect', this._connectListener)
      this._socket = null
    }

    emit(event, ...args) {
      this._queue.push({
        event,
        args,
      })
      this._deliver()
    }

    emitDirect(event, ...args) {
      if(!this._socket)
        return

      this._socket.emit(event, ...args)
    }

    _deliver() {
      if(!this._socket || !this._socket.connected)
        return

      while (this._queue.length) {
        const ev = this._queue.shift()
        this._socket.emit(ev.event, ...ev.args)
      }
    }

    on(event, listener) {
      if(!this._socket)
        return

      this._socket.on(event, listener)
    }

    off(event) {
      if(!this._socket) return

      this._socket.off(event)
    }
}
