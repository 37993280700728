import React from "react";
import {IconButton, Divider, Tooltip} from '@mui/material';
import RefreshIcon from "@mui/icons-material/Refresh";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Typography } from "@mui/material";
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import ChatIcon from '@mui/icons-material/Chat';
import SettingsIcon from '@mui/icons-material/Settings';


import { useDispatch, useSelector } from "react-redux";
import {
  setChatActive,
  selectConfig,
  setSideBarActive,
} from "../../slices/config";
import styles from "./MakeStyles.js";

function RightBtnGroup() {
  const classes = styles.RightBtnGroup();

  const config = useSelector(selectConfig);
  const { chatActive, sideBarActive } = config;
  const dispatch = useDispatch();

  return (
    <div className="right-controll-block">
        <Tooltip title={"Restart simulation"}>
          <IconButton
            className={classes.iconButton}
            aria-label="replay"
            component="span"
            size="large"
            onClick={() => window.location.reload()}
          >
            <RefreshIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      <Divider orientation="vertical" flexItem />
        <Tooltip title={"Open simulation configuration"}>
          <IconButton
            className={classes.iconButton}
            aria-label="replay"
            component="span"
            size="large"
            onClick={() => dispatch(setSideBarActive(!sideBarActive))}
          >
            <SettingsIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      <Divider orientation="vertical" flexItem />
        <Tooltip title={"Open chat"}>
          <IconButton
            className={classes.iconButton}
            aria-label="replay"
            component="span"
            size="large"
            onClick={() => dispatch(setChatActive(!chatActive))}
          >
            {chatActive ? (
                <ChatIcon fontSize="inherit" />
            ) : (
                <ChatOutlinedIcon fontSize="inherit" />
            )}
          </IconButton>
        </Tooltip>
      <Divider orientation="vertical" flexItem />
      <IconButton
        id="highlight-text-btn-off"
        onClick={() =>
          (window.location.href = `${window.location.origin}/app/char`)
        }
        className={classes.textButton}
        aria-label="replay"
      >
        <Typography variant="h5" display="block">
          End simulation
        </Typography>
      </IconButton>
      <IconButton
        className={classes.iconButton}
        id="highlight-btn-off"
        aria-label="replay"
        component="span"
        size="large"
        onClick={() =>
          (window.location.href = `${window.location.origin}/app/char`)
        }
      >
        <HighlightOffIcon fontSize="inherit" />
      </IconButton>
    </div>
  );
}

export default RightBtnGroup;
