import React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Typography } from "@mui/material";

import Header from "../Header";
import { CHARIMGS } from "../../config/constants.js";

const AddToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    borderRadius: 5,
  },
}));

const CharSelection = (charName) => {
  localStorage.setItem("chr", charName.toUpperCase());
  window.location.href = `${window.location.origin}/app`;
};

const CharCard = (props) => {
  return (
    <div className="char-component-options-card">
      {props.char.new ? (
        <AddToolTip
          title={
            <React.Fragment>
              <Typography arrow color="inherit">
                We're still working
              </Typography>
              <em>{"on connecting with 3rd party avatar creation apps."}</em>
              <br />
              <br />
              <em>{"Meanwhile, to build a new character, please go to "}</em>
              <u>
                <a
                  href="https://readyplayer.me"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://readyplayer.me
                </a>
              </u>
              <br />
              <em>{"and send us the GLB file."}</em>
            </React.Fragment>
          }
        >
          <img
            id="car-card-img"
            src={`/images/chars/${props.char.path}`}
            alt={`${props.char.name} character`}
          />
        </AddToolTip>
      ) : (
        <img
          id="car-card-img"
          src={`/images/chars/${props.char.path}`}
          alt={`${props.char.name} character`}
          onClick={() => CharSelection(props.char.name)}
        />
      )}
      <Typography mt={2} onClick={() => CharSelection(props.char.name)}>
        {props.char.name}
      </Typography>
    </div>
  );
};

const CharSelect = () => {
  return (
    <div>
      <Header />
      <div className="char-selection">
        <div className="char-component">
          <Typography variant="h4" component="h4">
            Choose character
          </Typography>
          <div className="char-component-options">
            {CHARIMGS.map((char, index) => {
              return <CharCard char={char} key={index} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CharSelect;
