import React, { useEffect, useRef, useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import GirlIcon from "@mui/icons-material/Girl";
import ManIcon from "@mui/icons-material/Man";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import IconButton from "@mui/material/IconButton";
import ChildCareIcon from "@mui/icons-material/ChildCare";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  InputLabel,
  Slider,
  Typography,
  Button,
} from "@mui/material";

import Player from "./helpers/Player.js";
import {
  gptDropModels,
  languages,
  neuralSpeakers,
  zoltarData,
} from "../config/GptData.js";
import styles from "./helpers/MakeStyles.js";
import conf from "../config";
import { useDispatch, useSelector } from "react-redux";
import {
  changeDetectionOrigin,
  enableEmotionsDetectionBox,
  enableObjectsDetectionBox,
  selectConfig,
  setGesturesEnabled,
  setGptTemperature,
  setLanguageCode,
  setVoice,
  setModelAddress,
  setSpeechWindow,
  setSttSensitivity,
  setSttTimeout,
  setSideBarActive
} from "../slices/config";
import { DETECTION_ORIGIN } from "../config/constants";
import { setCharacterPromptModalState } from "../slices/modals";

function Sidebar(props) {
  const { setRefresh } = props;
  const dispatch = useDispatch();
  const config = useSelector(selectConfig);
  const {
    modelAddress,
    detectionOrigin,
    characterPrompt,
    speechWindow,
    languageCode,
    voice,
    gesturesEnabled,
    isEmotionsBoxEnabled,
    isObjectsBoxEnabled,
    gptTemperature,
    sideBarActive
  } = config;
  const configRef = useRef(config);
  const [currentVoice, setCurrentVoice] = useState(voice);
  const showSidebar = () => dispatch(setSideBarActive(!sideBarActive));

  const classes = styles.sideStyle();

  useEffect(() => {
    // use case just for Zoltar character
    if (localStorage.getItem("chr") === "ZOLTAR") {
      neuralSpeakers.push(zoltarData);
    }
  }, []);

  useEffect(() => {
    if (!configRef.current.isEmotionsBoxEnabled && isEmotionsBoxEnabled) {
      dispatch(enableObjectsDetectionBox(false));
    } else if (!configRef.current.isObjectsBoxEnabled && isObjectsBoxEnabled) {
      dispatch(enableEmotionsDetectionBox(false));
    }

    configRef.current = config;
    // eslint-disable-next-line
  }, [config]);

  const onChangeModelAddress = (e) => {
    dispatch(setModelAddress(e.target.value));
    setRefresh(true);
  };

  const onChangeDetectionOrigin = (e) =>
    dispatch(changeDetectionOrigin(e.target.value));
  const onChangeLanguageCode = (e) => dispatch(setLanguageCode(e.target.value));
  const onChangeVoice = (e) => setCurrentVoice(e.target.value);
  const onChangeSpeechWindow = () => dispatch(setSpeechWindow(!speechWindow));
  const onChangeGesturesEnabled = () =>
    dispatch(setGesturesEnabled(!gesturesEnabled));
  const onChangeSttTimeout = (value) => dispatch(setSttTimeout(value));
  const onChangeSttSensitivity = (value) => dispatch(setSttSensitivity(value));
  const onChangeGptTemperature = (value) => dispatch(setGptTemperature(value));
  const onChangeEmotionsBoxState = () =>
    dispatch(enableEmotionsDetectionBox(!isEmotionsBoxEnabled));
  const onChangeObjectsBoxState = () =>
    dispatch(enableObjectsDetectionBox(!isObjectsBoxEnabled));

  const changeVoiceBtn = () => {
    if (currentVoice === voice) {
      return;
    }
    dispatch(setVoice(currentVoice));
  };

  const openCharacterPromptModal = () => {
    dispatch(
      setCharacterPromptModalState({
        isOpen: true,
        data: characterPrompt,
      })
    );
  };

  const renderDetectionSettings = () => {
    if (detectionOrigin === DETECTION_ORIGIN.SERVER) return null;
    return (
      <>
        <FormControl id="stt-slider" className={classes.formControl}>
          <Typography gutterBottom>Emotions recognition</Typography>
          <FormControlLabel
            control={
              <Switch
                color="warning"
                checked={isEmotionsBoxEnabled}
                onChange={onChangeEmotionsBoxState}
                name="emotions-detector-box"
              />
            }
            label="Enable boxes"
          />
        </FormControl>

        <FormControl id="stt-slider" className={classes.formControl}>
          <Typography gutterBottom>Objects recognition</Typography>
          <FormControlLabel
            control={
              <Switch
                color="warning"
                checked={isObjectsBoxEnabled}
                onChange={onChangeObjectsBoxState}
                name="object-detector-box"
              />
            }
            label="Enable boxes"
          />
        </FormControl>
      </>
    );
  };

  return (
    <nav className={sideBarActive ? "sidebar active" : "sidebar"}>
      <div className="hamburger">
        {sideBarActive ? (
          <KeyboardTabIcon fontSize="medium" onClick={showSidebar} />
        ) : (
          <MenuIcon fontSize="medium" onClick={showSidebar} />
        )}
      </div>
      <div className="sidebar-switchers">
        <FormControl component="fieldset">
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  color="warning"
                  checked={speechWindow}
                  onChange={onChangeSpeechWindow}
                  name="speechWindow"
                />
              }
              label="Speech window"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={gesturesEnabled}
                  onChange={onChangeGesturesEnabled}
                  name="gestures"
                />
              }
              label="Gesture engine"
            />
          </FormGroup>
        </FormControl>
        <FormControl variant="standard" className={classes.formControl}>
          <InputLabel id="selector-label">Character</InputLabel>
          <Select
            labelId="selector-label"
            id="selector-gpt"
            value={conf.getSelectedCharacter()}
            onChange={(e) => {
              conf.selectCharacter(e.target.value);
              localStorage.removeItem('promptset');
              window.location.reload();
            }}
          >
            {Object.keys(conf.constants.CHARACTERS).map((char) => (
              <MenuItem key={char} value={char}>
                <em>{char}</em>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className="voice-switcher">
          <FormControl variant="standard" className={classes.formControl}>
            <InputLabel id="selector-label">Voice</InputLabel>
            <Select
              labelId="selector-label"
              id="selector-voice"
              value={currentVoice}
              onChange={onChangeVoice}
            >
              {neuralSpeakers.map((voice) => (
                <MenuItem key={voice.name} value={voice.name}>
                  <em className="voice-element">
                    <div className="voice-element-left">
                      <p id="voiceName">{voice.name}</p>
                      {voice.gender === "F" ? (
                        <GirlIcon fontSize="small" />
                      ) : (
                        <ManIcon fontSize="small" />
                      )}
                      {voice.child ? <ChildCareIcon fontSize="small" /> : <></>}
                    </div>
                    <div className="voice-element-right">{voice.flag}</div>
                  </em>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Player url={currentVoice} />
          <IconButton
            size="small"
            color="primary"
            onClick={() => changeVoiceBtn()}
          >
            <CheckCircleOutlineIcon fontSize="small" />
          </IconButton>
        </div>
          <FormControl variant="standard" className={classes.formControl}>
            <Button variant="outlined" onClick={openCharacterPromptModal}>
              Edit prompt
            </Button>
          </FormControl>
        <FormControl variant="standard" className={classes.formControl}>
          <InputLabel id="selector-label">GPT model</InputLabel>
          <Select
            labelId="selector-label"
            id="selector-gpt"
            value={modelAddress}
            onChange={onChangeModelAddress}
          >
            {gptDropModels.map((model) => (
              <MenuItem key={model.key} value={model.value}>
                <em>{model.text}</em>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="standard" className={classes.formControl}>
          <InputLabel id="selector-label">Mic input language</InputLabel>
          <Select
            labelId="selector-label"
            id="selector-gpt"
            value={languageCode}
            onChange={onChangeLanguageCode}
          >
            {languages.map((model) => (
              <MenuItem key={model.value} value={model.value}>
                <em>
                  {model.text} {model.flag}
                </em>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl id="stt-slider" className={classes.formControl}>
          <Typography gutterBottom>STT timeout (sec)</Typography>
          <Slider
            defaultValue={0.75}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            onChangeCommitted={(e, value) => onChangeSttTimeout(value)}
            step={0.25}
            marks
            min={0.25}
            max={5}
          />
        </FormControl>

        <FormControl id="stt-slider" className={classes.formControl}>
          <Typography gutterBottom>STT mic sensitivity</Typography>
          <Slider
            defaultValue={0.1}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            onChangeCommitted={(e, value) => onChangeSttSensitivity(value)}
            step={0.01}
            marks
            min={0}
            max={0.35}
            color={"secondary"}
          />
        </FormControl>
        <FormControl id="stt-slider" className={classes.formControl}>
          <Typography gutterBottom>GPT-3 temperature</Typography>
          <Slider
            defaultValue={0.9}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            value={gptTemperature}
            onChangeCommitted={(e, value) => onChangeGptTemperature(value)}
            step={0.1}
            marks
            min={0}
            max={1}
            color={"secondary"}
          />
        </FormControl>
        <FormControl variant="standard" className={classes.formControl}>
          <InputLabel id="selector-label">Detection Origin</InputLabel>
          <Select
            labelId="selector-label"
            id="selector-d-e"
            value={detectionOrigin}
            onChange={onChangeDetectionOrigin}
          >
            {Object.keys(DETECTION_ORIGIN).map((char) => (
              <MenuItem key={char} value={DETECTION_ORIGIN[char]}>
                <em>{char}</em>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {renderDetectionSettings()}
      </div>
    </nav>
  );
}
export default Sidebar;
