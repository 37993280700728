import React from "react";
import { Typography } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import {useKeycloak} from "@react-keycloak/web";

function Header() {

  const { keycloak } = useKeycloak()

  const push = async () => {
    let redirectLocalUri = window.location.href;
    redirectLocalUri = redirectLocalUri.split("/")[0] + "//" + redirectLocalUri.split("/")[2] + "/logout";

      try {
          keycloak.logout({redirectUri: redirectLocalUri})
      }catch(e){
          console.error("Something went wrong ! ", e)
      }
  };

  return (
    <div className="top-nav-container">
      <div>
        <img src="/images/Logo.png" alt="Verbato logo" />
      </div>
      <div className="top-nav-logout">
        <Typography onClick={() => push()} mt={2}>Log out</Typography>
        <LogoutIcon onClick={() => push()} />
      </div>
    </div>
  );
}

export default Header;
