import React, {useState, useEffect, useRef, useContext} from "react";
import {useDispatch, useSelector} from "react-redux";
import mic from "microphone-stream";
import { IconButton } from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import { AwsWebSocketContext } from "./AwsWebSocket";

import styles from "./MakeStyles.js";
import {enableStt, setRecognizedText} from "../../slices/config";
import { setAsyncInterval, clearAsyncInterval } from "./utils";
import { EventsContext } from "../../eventsQueue";
import {selectEnteredMessage, sendSttToChat, setEnteredMessage} from "../../slices/chat";

const audioUtils = require("./audioUtils.js");
const marshaller = require("@aws-sdk/eventstream-marshaller");
const util_utf8_node = require("@aws-sdk/util-utf8-node");

function Stt({
  sttTimeout,
  ttsDuration,
  setTtsDuration,
}) {
  const awsWebSocketConnect = useContext(AwsWebSocketContext);
  const dispatch = useDispatch();
  const classes = styles.sttStyles();
  const { processingEvent, setIsPaused, queue } = useContext(EventsContext)
  const enteredMessage = useSelector(selectEnteredMessage)
  const [recording, setRecording] = useState(false);

  const awsWebSocketRef = useRef(null);
  const eventStreamMarshallerRef = useRef(new marshaller.EventStreamMarshaller(util_utf8_node.toUtf8, util_utf8_node.fromUtf8));
  const micStreamRef = useRef(null);
  const streamRef = useRef(null);
  const speechStartTime = useRef(0);
  const speechQueue = useRef([]);
  const speechQueueProcessingInterval = useRef(0);
  const speechProcessing = useRef(false);
  const stopProcessing = useRef(false)
  const isRecording = useRef(false)
  const submitSpeechTimeout = useRef(0)

  useEffect(() => {
    isRecording.current = !!recording
  }, [recording])

  useEffect(() => {
    (async () => {
      stopProcessing.current = !!processingEvent
      if (recording && !processingEvent && !awsWebSocketRef.current && !queue.length) {
        await onMicroResume()
      }
    })()
  // eslint-disable-next-line
  }, [processingEvent])


  useEffect(() => {
    // check to see if the browser allows mic access
    if (!window.navigator.mediaDevices.getUserMedia) {
      // Use our helper method to show an error on the page
      alert(
        "We support the latest versions of Chrome, Firefox, Safari, and Edge. Update your browser and try your request again."
      );

      // maintain enabled/distabled state for the start and stop buttons
      setRecording(false);
    }
  }, []);

  useEffect(() => {
    speechQueueProcessingInterval.current = setAsyncInterval(async () => {
      if (!speechQueue.current?.length || speechProcessing.current) return null;
      speechProcessing.current = true;

      speechQueue.current.shift();

      let currentTime = new Date().getTime();

      // console.log(speechStartTime.current, (currentTime - speechStartTime.current) / 1000, sttTimeout);
      if (speechStartTime.current && (currentTime - speechStartTime.current) / 1000 > sttTimeout) {
        speechStartTime.current = 0;
        if (submitSpeechTimeout.current) {
          console.log('clear timeout', submitSpeechTimeout.current)
          clearTimeout(submitSpeechTimeout.current)
        }
        submitStt()
      }

      speechProcessing.current = false;
    }, 1);

    return () => {
      clearAsyncInterval(speechQueueProcessingInterval.current)
    }
    // eslint-disable-next-line
  },[]);

  useEffect(() => {
    if (!awsWebSocketRef.current) return;
    setupListeners()
    // eslint-disable-next-line
  }, [awsWebSocketRef.current]);

  const setupListeners = () => {
    awsWebSocketRef.current.onmessage = onSocketMessage;
    awsWebSocketRef.current.onclose = function (closeEvent) {
      if (closeEvent.code !== 1000) {
        console.log("</i><strong>Streaming Exception</strong><br>" + closeEvent.reason);
      }

      onClose()
    };
  }


  const setupMicStream = async () => {
    streamRef.current = await navigator.mediaDevices.getUserMedia({
      video: false,
      audio: true,
    });
    streamAudioToWebSocket();
  }

  const onMicroResume = async () => {
    awsWebSocketRef.current = await awsWebSocketConnect();

    try {
      await setupMicStream()
      setupListeners()

      setRecording(true);
      dispatch(setRecognizedText(''));
      speechStartTime.current = 0;
      dispatch(enableStt(true))
    } catch (error) {
      console.log(error);
      // alert('There was an error streaming your audio to Amazon Transcribe. Please try again.');
      setRecording(false);
    }
  }


  // Mute/Unmute the microphone
  useEffect(() => {
    if (ttsDuration > 0 && micStreamRef.current) {
      micStreamRef.current.pauseRecording();
      console.log("Muted!");
      setTimeout(() => {
        setTtsDuration(0);
        micStreamRef.current.playRecording();
        console.log("Unmuted!");
      }, ttsDuration * 1000);
    }
  // eslint-disable-next-line
  }, [ttsDuration]);

  const onSocketMessage = (message) => {
    const messageWrapper = eventStreamMarshallerRef.current.unmarshall(Buffer(message.data));
    const messageBody = JSON.parse(String.fromCharCode.apply(String, messageWrapper.body));

    if (messageWrapper.headers[":message-type"].value !== "event") {
      console.log(messageBody.Message);
      return;
    }

    const results = messageBody?.Transcript?.Results || [];

    if (results.length > 0 && !stopProcessing.current && isRecording.current) {
      if (results[0].Alternatives.length > 0) {
        const result = results[0];
        const final = !result.IsPartial;
        // const prefix = final ? 'recognized' : 'recognizing';
        const text = result.Alternatives[0].Transcript;
        // console.log(`${prefix} text: ${text}`);
        addTextToDisplay(text, final);
      }
    }
  };

  const closeSocketConnection = () => {
    setTimeout(() => {
      if (awsWebSocketRef.current.readyState === awsWebSocketRef.current.OPEN) {
        let emptyMessage = getAudioEventMessage(Buffer.from(new Buffer([])));
        let emptyBuffer = eventStreamMarshallerRef.current.marshall(emptyMessage);
        awsWebSocketRef.current.send(emptyBuffer);
      }
    }, 1000)
  };

  const startBtn = async () => {
    awsWebSocketRef.current = await awsWebSocketConnect();
    console.log('awsWebSocketRef.current', awsWebSocketRef.current)
    setRecording(true);

    dispatch(setRecognizedText(''));
    dispatch(setEnteredMessage(''));
    speechStartTime.current = 0;

    try {
      await setupMicStream()
      dispatch(enableStt(true))
    } catch (error) {
      console.log(error);
      // alert('There was an error streaming your audio to Amazon Transcribe. Please try again.');
      setRecording(false);
    }
  };

 const submitStt = () => {
    dispatch(sendSttToChat())
    submitSpeechTimeout.current = setTimeout(() => setIsPaused(false), 3000)
    dispatch(setEnteredMessage(''));
 }

  const onClose = () => {
    try {
      onMicDisable();

      dispatch(enableStt(false));

      if (!streamRef.current) return;
      streamRef.current.getAudioTracks().forEach(function (track) {
        track.stop()
      });
      streamRef.current = null;
      awsWebSocketRef.current = null;
    } catch(e) {
      console.log('ERROR ON CLOSE', e)
    }
  }

  const stopBtn = () => {
    closeSocketConnection();
    onMicDisable();

    dispatch(setRecognizedText(enteredMessage));
    submitStt()
    setRecording(false);
    dispatch(enableStt(false));

    if (!streamRef.current) return;
    streamRef.current.getAudioTracks().forEach(function(track) { track.stop() });
    streamRef.current = null;
  };

  let streamAudioToWebSocket = function () {
    // let's get the mic input from the browser, via the microphone-stream module
    micStreamRef.current = new mic();
    micStreamRef.current.setStream(streamRef.current);

    // when we get audio data from the mic, send it to the WebSocket if possible
    micStreamRef.current.on("data", function (rawAudioChunk) {
      if (stopProcessing.current) return
      speechQueue.current.push({ chunk: mic.toRaw(rawAudioChunk), time: new Date().getTime() });

      if (awsWebSocketRef.current.readyState === awsWebSocketRef.current.OPEN) {
        let binary = convertAudioToBinaryMessage(rawAudioChunk);
        awsWebSocketRef.current.send(binary);
      }
    });
  };

  const onMicDisable = () => {
    if (!micStreamRef.current) return;

    micStreamRef.current.stop();
    micStreamRef.current.removeAllListeners();
    micStreamRef.current = null
  };

  const addTextToDisplay = (text, final) => {
    // console.log('text', text)
    if (submitSpeechTimeout.current) {
      clearTimeout(submitSpeechTimeout.current)
    }
    setIsPaused(true)
    dispatch(setEnteredMessage(text))

    if (final && recording) {
      speechStartTime.current = new Date().getTime();
      dispatch(setRecognizedText(text))
    }
  };

  function convertAudioToBinaryMessage(audioChunk) {
    let raw = mic.toRaw(audioChunk);
    if (raw === null) return;

    // downsample and convert the raw audio bytes to PCM
    let downsampledBuffer = audioUtils.downsampleBuffer(raw, undefined, 44100);
    let pcmEncodedBuffer = audioUtils.pcmEncode(downsampledBuffer);

    // add the right JSON headers and structure to the message
    let audioEventMessage = getAudioEventMessage(Buffer.from(pcmEncodedBuffer));

    //convert the JSON object + headers into a binary event stream message
    return eventStreamMarshallerRef.current.marshall(audioEventMessage);
  }

  function getAudioEventMessage(buffer) {
    // wrap the audio data in a JSON envelope
    //   console.log("buffer", buffer)
    return {
      headers: {
        ":message-type": {
          type: "string",
          value: "event",
        },
        ":event-type": {
          type: "string",
          value: "AudioEvent",
        },
      },
      body: buffer,
    };
  }

  const renderIcons = () => {
    if (!recording) {
      return (
        <IconButton
          id="animated-stt-btn"
          className={classes.iconMinOff}
          aria-label="mic"
          component="span"
          onClick={startBtn}
          size="large">
          <MicOffIcon fontSize="inherit" />
        </IconButton>
      );
    }

    return (
      <IconButton
        className={classes.iconMinOn}
        aria-label="mic"
        component="span"
        onClick={stopBtn}
        size="large">
        <MicIcon fontSize="inherit" />
        <div className="loadingio-spinner-eclipse-am1skuqtva">
          <div className="ldio-hs6233q6png">
            <div/>
          </div>
        </div>
      </IconButton>
    )
  };

  return (
    <div className="controll-btns">
      <div className="mic-btn-container">
        {renderIcons()}
      </div>
    </div>
  );
}

export default Stt;
