import { createSlice } from '@reduxjs/toolkit'

const name = 'chat'

const initialState = {
  chatLog: [],
  enteredMessage: '',
};

export const chatReducer = createSlice({
  name,
  initialState,
  reducers: {
    setChatLog: (state, action) => {
      state.chatLog = action.payload
    },
    setEnteredMessage: (state, action) => {
      state.enteredMessage = action.payload
    }
  },
});

export const {
  setChatLog,
  setEnteredMessage,
} = chatReducer.actions;

export const sendToChat = (message) => (dispatch, getState) => {
  const chatLog = getState().chat.chatLog
  dispatch(setChatLog([...chatLog, message]))
}

export const sendSttToChat = () => (dispatch, getState) => {
  const recognizedText = getState().config.recognizedText
  console.log('recognizedText => ', recognizedText)
  if (!recognizedText.length) return
  dispatch(sendToChat({ message: recognizedText, local: true }))
}

export const cleanChat = () => (dispatch) => {
  dispatch(setChatLog([]))
}

export const selectChatState = state => state[name]

export const selectChatLog = state => state[name].chatLog
export const selectEnteredMessage = state => state[name].enteredMessage

export default chatReducer.reducer
