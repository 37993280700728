import { CHARACTERS, OBJECT, UNEXPECTED, EXPRESSION, SPEECH, OBJECTS } from "./constants";
import { isSimilar } from "../components/helpers/chatUtils";
import { getSelectedCharacter } from "./sceneConfig";
import config from "./index";
import {EVENT_TYPES} from "../constants/events";

const scenarios = {
  [CHARACTERS.ZOLTAR]: [
    {
      id: 1,
      trigger: OBJECT,
      triggerObject: OBJECTS.PERSON,
      value: [
        {
          original: 'Hello. I am Zoltar the great gypsi, and I can see your future. Come see it, too.',
          speech: 'Хэлоу! Ай эм Золтар дa грэт жипси, энд ай кэн си юр фиущэр! Кам си ит, ту!',
        }
      ]
    },
    {
      id: 2,
      trigger: SPEECH,
      triggerObject: 'What is my future?',
      value:
        [
          {
            original: 'Zoltar will give you a lot of wisdom. Age is simply a matter of mind, if you don\'t mind my friend, it doesn\'t matter. Be careful like a little baby',
            speech: 'Золтар вил гив ю э лот оф уиздом. Эйдж ис симпли a мaтэр оф дa майнд, иф ю донт майнд, май фрэнд, ит даз нoт мaьтэр. Би кейрфул лайк э литл бeйби.',
          },
/*          {
            original: 'A smile is worth a hundred frowns in this market. I see a great deal of happiness in store for you',
            speech: 'Э смайл ис ворс э хандрэд фраунс ин зис маркет. Ай си э грэйт дил оф хеппинэс ин стор фо ю',
          },
          {
            original: 'You will receive a letter soon, and that letter can easily be said to change the whole course of your life',
            speech: 'Ю вил рисив a лэттэр сун. энд дaт лэттэр кэн изили би сэд ту чэйнж дa хол корс оф йор лайф.',
          },
          {
            original: 'You deserve this happiness because you have been so faithful and sincere in your love',
            speech: 'ю дизэрвь дис хапинэс бикоз ю хaвь бин су фэитфул aнд синсир ин юр лов.',
          },*/
        ],
    },
    {
      id: 3,
      trigger: SPEECH,
      triggerObject: 'Thank you.',
      value:
        [
          {
            original: 'You are welcome! Do you want to give me more gifts, and I will tell you more about the future?',
            speech: 'ю ap уэлком! ду ю уант ту гивъ ми мор гифтс, энд ай уил тэл иу мор абаыт иор фиущэр?',
          },
        ],
    },
    {
      id: 4,
      trigger: OBJECT,
      triggerObject: OBJECTS.BOOK,
      value: [
        {
          original: 'Books are nice, but I prefer the George Washington.',
          speech: 'букs ap найs, бат ай прифэр э джордж вашингтон.',
        },
      ]
    },
    {
      id: 5,
      trigger: SPEECH,
      triggerObject: "I don’t have any cash on me",
      value:
        [
          {
            original: 'No problem my friend, we may speak at another time.',
            speech: 'нo прoублэм май фрэнд, we мэй sпиk эт aнoзэр тайм.',
          },
        ],
    },
    {
      id: 6,
      trigger: UNEXPECTED,
      triggerObject: '',
      value: [
        {
          original: 'I am sorry,  there must be some interruption',
          speech: 'ай эм сoри! дэр мaст би сaм интэрaпшэн.',
        },
        {
          original: 'My friend, do you speak English? I can’t understand you',
          speech: 'май фрэнд, ду ю спик инглиш? ай кэн нот андэрстэнд ю.',
        },
        {
          original: 'Do you mind repeating that?',
          speech: 'ду ю майнд, рипитинг зэт?',
        },
        {
          original: 'Zoltar doesn\'t understand. Say that again?',
          speech: 'золтар даз нот андэрстэнд, сэй дэт агэйн?',
        }
      ]
    }
  ]
};

export const loadScenario = (character) => scenarios[character];
export const executeScenario = (step, type, value) => {
  const text = (step.value || [])[Math.floor(Math.random() * step.value.length)];

  if (step.trigger === SPEECH && type === SPEECH) {
    if (isSimilar(step.triggerObject, value) >= 70) return text
  }

  if (step.trigger === EXPRESSION && type === EVENT_TYPES.EXPRESSION_DETECTED) {
    if (step.triggerObject === value) return text
  }

  if (step.trigger === OBJECT && type === EVENT_TYPES.OBJECT_DETECTED) {
    if (step.triggerObject === value) return text
  }

  if (step.trigger === UNEXPECTED && type === SPEECH) {
    return text
  }
};

export const isScenarioCharacter = () => getSelectedCharacter() === CHARACTERS.ZOLTAR;

export const onScenarioHandler = (scenario, type, value) => {
  const { UNEXPECTED } = config.constants;
  const sendResult = (stepId = 0, scenarioText = '') => ({ stepId, scenarioText });

  if (!isScenarioCharacter()) return sendResult();

  const unexpectedStep = scenario.find((s) => s.trigger === UNEXPECTED);
  const dynamicScenario = scenario.filter(s => s.id !== unexpectedStep.id);

  if (!dynamicScenario.length) {
    return sendResult(0, executeScenario(unexpectedStep, type, value))
  }

  const step = dynamicScenario[0];
  let result = executeScenario(step, type, value);

  if (!result) {
    if (type === SPEECH) return sendResult(0, executeScenario(unexpectedStep, type, value))
    return sendResult()
  }

  return sendResult(step.id, result);
};
