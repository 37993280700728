import axios from 'axios'

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
        headers: {
            'Origin': 'https://sandbox.verbato.ai/'
        }
    }
)

export const getUserInfo = async (token) => {
    try {
        const result = await instance.get('v1/auth/user-info', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
    }catch(err) {
        console.log('err ', err)
    }
}
export const syntesApiSpeech = async (params) => {
    const token = localStorage.getItem('access_token')

    return await instance.post('v1/ai/speech', {
      ...params
  },{
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
}
export const getVoices = async ( ) => {
    const token = localStorage.getItem('access_token')
    return await instance.get('v1/ai/voices', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
}

export const getAudioUrl = async (params) => {
    const token = localStorage.getItem('access_token')
    return await instance.post('v1/ai/audio-url', {
        ...params
    },{
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
}

export const sendCompletions = async (modelAddress, genereateReq, chatLog, recognizedText) => {
    const access_token = localStorage.getItem('access_token')
    return await instance.post(
        `v1/ai/${modelAddress}/completions`,
        genereateReq([...chatLog, { message: recognizedText, local: true }]),
        {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }
    );
}
