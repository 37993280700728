import React from "react";
import LoaderDiv from "./LoaderDiv";

// todo probably need to remove
const SignCallback = async () => {
  // const queryParams = new URLSearchParams(window.location.hash.replace('#', ''));
  // const state = queryParams.get("state");
  // const access_token = queryParams.get("access_token")

  // if (state && access_token) {

  // }

  return (
    <LoaderDiv />
  );
};

export default SignCallback;
