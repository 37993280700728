import React from "react";
import { useSelector } from "react-redux";
import { selectSpeechWindow } from "../../slices/config";

import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import NativeSelect from "@mui/material/NativeSelect";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

function SpeechWindow({ speechWindowText, setSpeechWindowText }) {
  const speechWindow = useSelector(selectSpeechWindow);
  return (
    <div
      id="textToSpeech"
      style={{ display: speechWindow ? "inline-block" : "none" }}
    >
      <div className="textEntry-tool">
        <button className="tab current">Speech</button>
        <div>
          <textarea
            autoFocus
            value={speechWindowText}
            size="23"
            type="text"
            onChange={(e) => {
              setSpeechWindowText(e.target.value);
            }}
            className="textEntry Cristine"
          />
        </div>
        <ButtonGroup
          variant="contained"
          aria-label="outlined primary button group"
          fullWidth={true}
        >
          <Button size="small" id="play">
            Play
          </Button>
          <Button size="small" id="pause">
            Pause
          </Button>
          <Button size="small" id="resume">
            Resume
          </Button>
          <Button size="small" id="stop">
            Stop
          </Button>
        </ButtonGroup>
        <div className="textEntry-tool-gen">
          <Button variant="contained" id="gestures" color="success">
            Generate Gestures
          </Button>
        </div>
      </div>

      <div className="animations-tool">
        <Paper elevation={3} className="gestures-tool">
          <FormControl>
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
              Emote
            </InputLabel>
            <NativeSelect
              inputProps={{
                name: "Emote",
                id: "emotes",
              }}
            />
          </FormControl>
          <IconButton size="small" color="primary" id="playEmote">
            <PlayCircleOutlineIcon fontSize="medium" />
          </IconButton>
        </Paper>
        <Paper elevation={3} className="gestures-tool">
          <FormControl>
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
              Gesture
            </InputLabel>
            <NativeSelect
              inputProps={{
                name: "Gesture",
                id: "gesturesSelect",
              }}
            />
          </FormControl>
          <IconButton size="small" color="primary" id="playGesture">
            <PlayCircleOutlineIcon fontSize="medium" />
          </IconButton>
        </Paper>
      </div>
    </div>
  );
}

export default SpeechWindow;
