import * as faceapi from '@vladmandic/face-api';

export const SSD_MOBILENETV1 = 'ssd_mobilenetv1';
export const TINY_FACE_DETECTOR = 'tiny_face_detector';

// ssd_mobilenetv1 options
let minConfidence = 0.5;

// tiny_face_detector options
let inputSize = 128;
let scoreThreshold = 0.5;

function getCurrentFaceDetectionNet(selectedFaceDetector) {
  if (selectedFaceDetector === SSD_MOBILENETV1) {
    return faceapi.nets.ssdMobilenetv1
  }
  if (selectedFaceDetector === TINY_FACE_DETECTOR) {
    return faceapi.nets.tinyFaceDetector
  }
}

function isFaceDetectionModelLoaded(selectedFaceDetector) {
  return !!getCurrentFaceDetectionNet(selectedFaceDetector).params
}

function getFaceDetectorOptions(selectedFaceDetector) {
  return selectedFaceDetector === SSD_MOBILENETV1
    ? new faceapi.SsdMobilenetv1Options({ minConfidence })
    : new faceapi.TinyFaceDetectorOptions({ inputSize, scoreThreshold })
}

export {
  isFaceDetectionModelLoaded,
  getFaceDetectorOptions,
  getCurrentFaceDetectionNet,
}
