// Data for GPT models usage
const gptDropModels = [
  { key: "text-davinci-002", text: "text-davinci-002", value: "text-davinci-002" },
  { key: "text-curie-001", text: "text-curie-001", value: "text-curie-001" },
  { key: "text-babbage-001", text: "text-babbage-001", value: "text-babbage-001"},
  { key: "text-ada-001", text: "text-ada-001", value: "text-ada-001" },
];

// STT languages codes
const languages = [
  { text: "en-US", value: "en-US", flag: "🇺🇸" },
  { text: "en-GB", value: "en-GB", flag: "🇬🇧" },
  { text: "en-AU", value: "en-AU", flag: "🇦🇺" },
];

// TTS voices
const neuralSpeakers = [
  { name: "Olivia", lang: "en-AU", gender: "F", child: false, flag: "🇦🇺" },
  { name: "Amy", lang: "en-GB", gender: "F", child: false, flag: "🇬🇧" },
  { name: "Emma", lang: "en-GB", gender: "F", child: false, flag: "🇬🇧" },
  { name: "Brian", lang: "en-GB", gender: "M", child: false, flag: "🇬🇧" },
  { name: "Aria", lang: "en-NZ", gender: "F", child: false, flag: "🇳🇿" },
  { name: "Ayanda", lang: "en-ZA", gender: "F", child: false, flag: "🇿🇦" },
  { name: "Ivy", lang: "en-US", gender: "F", child: true, flag: "🇺🇸" },
  { name: "Joanna", lang: "en-US", gender: "F", child: false, flag: "🇺🇸" },
  { name: "Kendra", lang: "en-US", gender: "F", child: false, flag: "🇺🇸" },
  { name: "Kimberly", lang: "en-US", gender: "F", child: false, flag: "🇺🇸" },
  { name: "Salli", lang: "en-US", gender: "F", child: false, flag: "🇺🇸" },
  { name: "Joey", lang: "en-US", gender: "M", child: false, flag: "🇺🇸" },
  { name: "Justin", lang: "en-US", gender: "M", child: true, flag: "🇺🇸" },
  { name: "Kevin", lang: "en-US", gender: "M", child: true, flag: "🇺🇸" },
  { name: "Matthew", lang: "en-US", gender: "M", child: false, flag: "🇺🇸" },
];

const zoltarData = { name: "Maxim", lang: "ru-RU", gender: "M", child: false, flag: "🇷🇺" };

module.exports = {
  gptDropModels,
  languages,
  neuralSpeakers,
  zoltarData,
};
