import React from "react";

function LoaderDiv() {
  
  const runCallback = (cb) => {
    return cb();
  };

  return (
    <div id="loadScreen">
      <div className="big-spinner">
        <div className="big-spinner-container">
        {runCallback(() => {
          const stick = [];
          for (var i = 0; i < 16; i++) {
            stick.push(<div key={i} />);
          }
          return stick;
        })}
        </div>
      </div>
    </div>
  );
}

export default LoaderDiv;
