import {createContext, useEffect, useRef, useState} from 'react'
import {sleep} from "../components/helpers/utils";

export const EventsContext = createContext(null)

const EventsQueue = ({ children }) => {
  const [queue, setQueue] = useState([])
  const [processingEvent, setProcessingEvent] = useState(null)
  const [isPaused, setIsPaused] = useState(false)
  const timerRef = useRef(0)
  const currentItem = useRef(null)
  const currentlyPaused = useRef(false)

  useEffect(() => {
    watchQueue()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    currentItem.current = processingEvent
  }, [processingEvent])

  useEffect(() => {
    currentlyPaused.current = isPaused
  }, [isPaused])

  const handleQueue = (processingEvent, isPaused) => {
    if (processingEvent || isPaused) {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
      return
    }

    function takeNext() {
      let itemToProcess = null
      setQueue(prevState => {
        if (!prevState.length) return prevState
        const [firstItem, ...restArray] = prevState
        itemToProcess = firstItem
        return restArray
      })
      if (!itemToProcess) return
      setProcessingEvent(itemToProcess)
    }

    if (queue.length) {
      timerRef.current = setTimeout(takeNext,1000)
      return null
    }
    takeNext()
  }

  const watchQueue = async () => {
    processQueue()
    handleQueue(currentItem.current, currentlyPaused.current)
    await sleep(300)
    await watchQueue()
  }


  function processQueue() {
    setQueue((prev) => {
      if (!prev.length) return prev
      return prev
        .filter(i => !(i.expireDate && i.expireDate && i.expireDate < Date.now()))
        .sort((a,b) => {
          if(a.weight < b.weight) return 1;
          if(a.weight > b.weight) return -1;
          return 0;
        })
    })
  }

  const pushToQueue = (event) => {
    setQueue((prev) => [...prev, event])
  }

  const finishEvent = () => {
    setProcessingEvent(null)
  }

  const createEvent = (event) => {
    const {
      weight = 1000,
      expireDate = null, // 10 sec
      type = null,
      data = null,
    } = event
    if (!type) throw new Error('event type is required')

    return {
      weight,
      type,
      data,
      expireDate,
    }
  }

  return (
    <EventsContext.Provider value={{
      queue,
      processingEvent,
      isPaused,
      pushToQueue,
      createEvent,
      finishEvent,
      setIsPaused,
    }}>
      {children}
    </EventsContext.Provider>
  )
}

export default EventsQueue
