import {CHARACTERS, DETECTION_ORIGIN} from "./constants";
import * as THREE from "three";

const defaultSceneConfig = {
  roomFilePath: "./glTF/environment/room_lights_only_01.glb",
  characterFilePath: "./glTF/character/Tory_RPM_01.glb",
  animationPath: "./glTF/animations/rpm",
  bgTexturePath: "./images/colorful_studio_270.jpg",
  audioAttachJoint: "Head",
  lookJoint: "HeadTop_End",
  voice: "Justin",
  cameraOptions: [
    THREE.MathUtils.radToDeg(0.8),
    window.innerWidth / window.innerHeight,
    0.1,
    1000
  ],
  cameraPosition: [0, 1.3, 20],
  controlsTarget: [0, 1.3, 0],
};

const defaultCharacter = CHARACTERS.TORI;

const charactersConfigs = {
  [CHARACTERS.TORI]: {
    characterFilePath: './glTF/character/Tory_RPM_01.glb',
    voice: 'Justin',
  },
  [CHARACTERS.TOM]: {
    characterFilePath: './glTF/character/tom_brady.glb',
    roomFilePath: "./glTF/environment/press_conference.glb",
    voice: 'Matthew',
  },
  [CHARACTERS.ZOLTAR]: {
    roomFilePath: "./glTF/environment/room_lights_only_01.glb",
    cameraOptions: [
      THREE.MathUtils.radToDeg(0.4),
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    ],
    cameraPosition: [0, 1.6, 1.5],
    controlsTarget: [0, 1.6, 0],
    characterFilePath: './glTF/character/Zoltar_04.glb',
    bgTexturePath: "./images/fair.jpg",
    voice: 'Maxim',
  },
  [CHARACTERS.FREDDY]: {
    roomFilePath: "./glTF/environment/Truck_01.glb",
    characterFilePath: './glTF/character/freddy.glb',
    bgTexturePath: "./images/vegas.jpg",
    voice: 'Matthew',
  },
  [CHARACTERS.CHANI]: {
    roomFilePath: "./glTF/environment/Dunes.glb",
    characterFilePath: './glTF/character/Chani.glb',
    bgTexturePath: "./images/Dunes.jpg",
    voice: 'Justin',
  }
};

export const createSceneConfig = (character = '') => {
  if (!character) character = getSelectedCharacter();
  const characterConfig = charactersConfigs[character] || {};
  return { ...defaultSceneConfig, ...characterConfig };
};

export const getCurrentVoice = (character = getSelectedCharacter()) => {
  return charactersConfigs[character].voice;
}

export const getSelectedCharacter = () => {
  return localStorage.getItem('chr') || defaultCharacter;
};

export const getDetectorOrigin = () => {
  return localStorage.getItem('det-orig') || DETECTION_ORIGIN.BROWSER
}

export const selectCharacter = (character = CHARACTERS.TORI) => {
  let chr = defaultCharacter;
  if (CHARACTERS[character]) chr = character;
  localStorage.setItem('chr', chr);
  localStorage.removeItem('chr-voice');
};
