import React, { createContext } from "react";

const crypto = require("crypto");
const v4 = require("./aws-signature-v4");

const AwsWebSocketContext = createContext(null);
export { AwsWebSocketContext };

function AwsWebSocket({ children }) {
  const createConnectionUrl = () => {
    const AWS_REGION = "us-west-2";
    const SAMPLE_RATE = 44100;
    const LANGUAGE_CODE = "en-US";

    let endpoint = "transcribestreaming." + AWS_REGION + ".amazonaws.com:8443";

    // get a preauthenticated URL that we can use to establish our WebSocket
    return v4.createPresignedURL(
      "GET",
      endpoint,
      "/stream-transcription-websocket",
      "transcribe",
      crypto.createHash("sha256").update("", "utf8").digest("hex"),
      {
        key: process.env.REACT_APP_TRANSCRIBE_ACCESSKEYID,
        secret: process.env.REACT_APP_TRANSCRIBE_SECRETKEY,
        protocol: "wss",
        expires: 60,
        region: AWS_REGION,
        query:
          "language-code=" +
          LANGUAGE_CODE +
          "&media-encoding=pcm&sample-rate=" +
          SAMPLE_RATE,
      }
    );
  };

  const connect = async () => {
    const ws = new WebSocket(createConnectionUrl());
    ws.binaryType = "arraybuffer"; // or "blob"

    const onOpen = new Promise((resolve) => {
      ws.onopen = () => {
        resolve();
      };
    });

    await onOpen;

    // ws.addEventListener("message", function (event) {
    //   if (event.data instanceof ArrayBuffer) {
    //     const view = new DataView(event.data);
    //     console.log('binary frame =>', view.getInt32(0));
    //   } else {
    //     console.log('text frame', event.data);
    //   }
    // });

    return ws;
  };

  return (
    <AwsWebSocketContext.Provider value={connect}>
      {children}
    </AwsWebSocketContext.Provider>
  );
}

export default AwsWebSocket;
