import React, { useContext, useEffect, useRef, useState } from "react";

import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

import HOST from "./tools/three.js/index.js";
import poiConfig from "./poi.json";
import config from "../config";
import gestureConfig from "./gesture.json";
import AWS from "aws-sdk";

import SpeechWindow from "./tools/SpeechWindow.js";
import LoaderDiv from "../components/helpers/LoaderDiv.js";
import { useDispatch, useSelector } from "react-redux";
import { selectGesturesEnabled } from "../slices/config";
import { EventsContext } from "../eventsQueue";
import { EVENT_TYPES } from "../constants/events";
import { sendToChat } from "../slices/chat";

const renderFn = [];
const speakers = new Map([["Cristine", undefined]]);



AWS.config.update({
  region: "us-west-2",
  accessKeyId: process.env.REACT_APP_POLLY_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_POLLY_SECRETKEY,
});

function ThreeDiv(props) {
  const { setTtsDuration, neuralEngine } = props;
  const gesturesEnabled = useSelector(selectGesturesEnabled);
  const { processingEvent, finishEvent } = useContext(EventsContext);
  const dispatch = useDispatch();

  const [speechWindowText, setSpeechWindowText] = useState(
    "Hello, how is it going today?"
  );

  const sceneConfig = useRef(config.createSceneConfig());
  const listener = useRef({});

  useEffect(() => {
    main();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      if (processingEvent?.type !== EVENT_TYPES.TTS_PROCESSING) return;
      const { value, speech } = processingEvent.data;
      const speechText = speech || value;
      if (!speechText) return;
      console.log("---Three-input-->", speechText);
      const { host } = getCurrentHost(speakers);


      if (gesturesEnabled) {
        // Generate gestures to the GPT responce text
        const gestureMap = host.GestureFeature.createGestureMap();
        const gestureArray = host.GestureFeature.createGenericGestureArray([
          "Gesture",
        ]);
        const ttsGeist = HOST.aws.TextToSpeechUtils.autoGenerateSSMLMarks(
          speechText,
          gestureMap,
          gestureArray
        );

       // console.log('---Gesture-text-->', ttsGeist);
       host.TextToSpeechFeature["play"](ttsGeist, {}, () => {
         dispatch(
             sendToChat({
               message: value,
               local: false,
             })
         );

       });
        // console.log("this.listener", this.listener);
        // receive the responce duration of STT audio from AWS libs
        setTimeout(() => {
          setTtsDuration(listener.current.duration);
        }, 700);
      } else {
        // Play responce without generated gestures
        host.TextToSpeechFeature["play"](speechText, {}, () => {
          dispatch(
              sendToChat({
                message: value,
                local: false,
              })
          );

        });
      }
    })();
    // eslint-disable-next-line
  }, [processingEvent]);

  const main = async () => {
    const speechInit = HOST.aws.TextToSpeechFeature.initializeService(
      new AWS.Polly(),
      new AWS.Polly.Presigner(),
      AWS.VERSION
    );

    const characterFile = sceneConfig.current.characterFilePath;
    const animationPath = sceneConfig.current.animationPath;
    const animationFiles = [
      "stand_idle.glb",
      "lipsync.glb",
      "gesture.glb",
      "emote.glb",
      "face_idle.glb",
      "blink.glb",
      "poi.glb",
    ];
    const audioAttachJoint = sceneConfig.current.audioAttachJoint; // Name of the joint to attach audio to
    const lookJoint = sceneConfig.current.lookJoint; // Name of the joint to use for point of interest target tracking
    const voice =
      localStorage.getItem("chr-voice") || sceneConfig.current.voice;
    const voiceEngine = neuralEngine;

    const { scene, camera, clock } = createScene();
    const {
      character: mainCharacter,
      clips: mainClips,
      bindPoseOffset: mainBindPoseOffset,
    } = await loadCharacter(
      scene,
      characterFile,
      animationPath,
      animationFiles
    );

    // Find the joints defined by name
    const audioAttach = mainCharacter.getObjectByName(audioAttachJoint);
    const lookTracker = mainCharacter.getObjectByName(lookJoint);

    const [
      idleClips,
      lipsyncClips,
      gestureClips,
      emoteClips,
      faceClips,
      blinkClips,
      poiClips,
    ] = mainClips;
    const host1 = createHost(
      mainCharacter,
      audioAttach,
      voice,
      voiceEngine,
      idleClips[0],
      faceClips[0],
      lipsyncClips,
      gestureClips,
      gestureConfig,
      emoteClips,
      blinkClips,
      poiClips,
      poiConfig,
      lookTracker,
      mainBindPoseOffset,
      clock,
      camera,
      scene
    );

    // Points of interest
    const onHost1StartSpeech = () => {
      host1.PointOfInterestFeature.setTarget(camera);
    };
    const onStopSpeech = () => {
      host1.PointOfInterestFeature.setTarget(camera);
      finishEvent();
    };
    const onPauseSpeech = () => {
      host1.PointOfInterestFeature.setTarget(camera);
    };

    host1.listenTo(host1.TextToSpeechFeature.EVENTS.play, onHost1StartSpeech);
    host1.listenTo(host1.TextToSpeechFeature.EVENTS.resume, onHost1StartSpeech);
    HOST.aws.TextToSpeechFeature.listenTo(
      HOST.aws.TextToSpeechFeature.EVENTS.pause,
      onPauseSpeech
    );
    HOST.aws.TextToSpeechFeature.listenTo(
      HOST.aws.TextToSpeechFeature.EVENTS.stop,
      onStopSpeech
    );

    await speechInit;

    const disableLoader = () => {
      // Turning off loading div after the room loading
      document.getElementById("loadScreen").style.visibility = "hidden";
      document.getElementById("loadScreen").style.opacity = 0;
    };

    // Room model loading block
    if (sceneConfig.current.roomFilePath) {
      const roomLoader = new GLTFLoader();
      roomLoader.load(
        sceneConfig.current.roomFilePath,
        (gltf) => {
          scene.add(gltf.scene);
          disableLoader();
        },
        undefined,
        function (error) {
          console.error(error);
        }
      );
    } else disableLoader();

    speakers.set("Cristine", host1);
    initializeUX();
  };

  // ================

  const getCurrentHost = () => {
    const name = speakers.entries().next().value[0]; // Receiving name from the Map
    return { name, host: speakers.get(name) };
  };

  const toggleHost = (evt) => {
    const tab = evt.target;
    const allTabs = document.getElementsByClassName("tab");

    // Update tab classes
    for (let i = 0, l = allTabs.length; i < l; i++) {
      if (allTabs[i] !== tab) {
        allTabs[i].classList.remove("current");
      } else {
        allTabs[i].classList.add("current");
      }
    }

    // Show/hide speech input classes
    const { name, host } = getCurrentHost(speakers);
    const textEntries = document.getElementsByClassName("textEntry");

    for (let i = 0, l = textEntries.length; i < l; i += 1) {
      const textEntry = textEntries[i];

      if (textEntry.classList.contains(name)) {
        textEntry.classList.remove("hidden");
      } else {
        textEntry.classList.add("hidden");
      }
    }

    // Update emotions and gestures selectors
    const emoteSelect = document.getElementById("emotes");
    const gestureSelect = document.getElementById("gesturesSelect");

    emoteSelect.length = 0;
    gestureSelect.length = 0;

    const emotes = host.AnimationFeature.getAnimations("Emote");
    const gestures = host.AnimationFeature.getAnimations("Gesture");

    host.GestureFeature.playGesture("Gesture", "wave"); // Show 'hello' animation on the beginning

    emotes.forEach((emote, i) => {
      const emoteOption = document.createElement("option");
      emoteOption.text = emote;
      emoteOption.value = emote;
      emoteSelect.add(emoteOption, 0);

      // Set the current item to the first emote
      if (!i) {
        emoteSelect.value = emote;
      }
    });

    gestures.forEach((gesture, i) => {
      const gestureOption = document.createElement("option");
      gestureOption.text = gesture;
      gestureOption.value = gesture;
      gestureSelect.add(gestureOption, 0);

      // Set the current item to the first gesture
      if (!i) {
        gestureSelect.value = gesture;
      }
    });
  };

  // ================

  const initializeUX = (speakers) => {
    // Connect tab buttons to hosts
    Array.from(document.getElementsByClassName("tab")).forEach((tab) => {
      tab.onclick = (evt) => {
        toggleHost(evt);
      };
    });

    // Play, pause, resume and stop the contents of the text input as speech
    // when buttons are clicked
    ["play", "pause", "resume", "stop"].forEach((id) => {
      const button = document.getElementById(id);
      button.onclick = () => {
        const { name, host } = getCurrentHost(speakers);
        const speechInput = document.getElementsByClassName(
          `textEntry ${name}`
        )[0];
        host.TextToSpeechFeature[id](speechInput.value);
      };
    });

    // Update the text area text with gesture SSML markup when clicked
    const gestureButton = document.getElementById("gestures");
    gestureButton.onclick = () => {
      const { name, host } = getCurrentHost(speakers);
      const speechInput = document.getElementsByClassName(
        `textEntry ${name}`
      )[0];
      const gestureMap = host.GestureFeature.createGestureMap();
      const gestureArray = host.GestureFeature.createGenericGestureArray([
        "Gesture",
      ]);
      speechInput.value = HOST.aws.TextToSpeechUtils.autoGenerateSSMLMarks(
        speechInput.value,
        gestureMap,
        gestureArray
      );
    };

    // Play emote on demand with emote button
    const emoteSelect = document.getElementById("emotes");
    const gestureSelect = document.getElementById("gesturesSelect");
    const emoteButton = document.getElementById("playEmote");
    const playGesture = document.getElementById("playGesture");

    emoteButton.onclick = () => {
      const { host } = getCurrentHost(speakers);
      host.GestureFeature.playGesture("Emote", emoteSelect.value);
    };

    playGesture.onclick = () => {
      const { host } = getCurrentHost(speakers);
      host.GestureFeature.playGesture("Gesture", gestureSelect.value);
    };

    // Initialize tab
    const tab = document.getElementsByClassName("tab current")[0];
    toggleHost({ target: tab });
  };

  // ===============

  const createScene = () => {
    // Base scene
    const scene = new THREE.Scene();
    const clock = new THREE.Clock();
    scene.background = new THREE.Color(0x33334d);

    if (sceneConfig.current.bgTexturePath) {
      const loader = new THREE.TextureLoader();
      const texture = loader.load(sceneConfig.current.bgTexturePath, () => {
        const rt = new THREE.WebGLCubeRenderTarget(texture.image.height);
        rt.fromEquirectangularTexture(renderer, texture);
        scene.background = rt.texture;
      });
    }

    // Renderer
    const container = document.getElementById("video-content");
    let w = container.offsetWidth;
    let h = container.offsetHeight;

    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(w, h);
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.shadowMap.enabled = true;
    renderer.setClearColor(0x33334d);
    renderer.domElement.id = "renderCanvas";
    container.appendChild(renderer.domElement);

    renderer.physicallyCorrectLights = true;

    const pmremGenerator = new THREE.PMREMGenerator(renderer);
    pmremGenerator.compileEquirectangularShader();

    // Camera
    const camera = new THREE.PerspectiveCamera(
      ...sceneConfig.current.cameraOptions
    );
    const controls = new OrbitControls(camera, renderer.domElement);
    camera.position.set(...sceneConfig.current.cameraPosition);
    controls.target = new THREE.Vector3(...sceneConfig.current.controlsTarget);
    controls.screenSpacePanning = true;
    camera.aspect = container.offsetWidth / container.offsetHeight;

    // Disable panning
    controls.enablePan = false;
    // Distance limitations
    controls.minDistance = 0.6;
    controls.maxDistance = 1.7;
    // Vertical limitations
    controls.minPolarAngle = 1; // radians
    controls.maxPolarAngle = 2;
    // Horizontal limitations
    controls.minAzimuthAngle = -0.5;
    controls.maxAzimuthAngle = 0.7;
    controls.update();

    // Handle window resize
    function onWindowResize(sideCorrection) {
      let connection = 0;
      if (sideCorrection > 0) {
        connection += sideCorrection;
      }
      // Resize via relative chat component
      // let fullwidth = (window.innerWidth - document.getElementById("chat-div").offsetWidth - 50 - connection );
      // let fullwidth = (window.innerWidth - document.getElementById("chat-div").offsetWidth - connection );
      let fullwidth = container.offsetWidth - connection;
      // if (window.innerWidth <= 600) {
      //   fullwidth = container.offsetWidth - connection;
      // }
      camera.aspect = fullwidth / container.offsetHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(
        fullwidth,
        container.offsetHeight
      );
    }

    window.onresize = onWindowResize;

    // Handle aspect ration inside render container after component mounting
    document.querySelector(".sidebar").addEventListener("animationend", () => {
      onWindowResize();
    });

    // // Handle render window resize after Chat component transitions
    // document.getElementById("chat-open-div").addEventListener("click", () => {
    //   setTransitionMark(true); // Click that will open the chat
    //   onWindowResize(250); // Free some space for chat opening
    // });

    // document.getElementById("chat-close-div").addEventListener("click", () => {
    //   setTransitionMark(false); // Click that will close the chat
    //   setTimeout(() => {
    //     onWindowResize(); // Free some space for chat closing
    //   }, 350);
    // });

    // document.querySelector(".chat").addEventListener("transitionend", () => {
    //   if (document.querySelector("chat-open") === null && !transitionMark) {
    //     onWindowResize(); // Resize after chat closing
    //   }
    // });

    // Render loop
    function render() {
      requestAnimationFrame(render);
      // controls.update();
      renderFn.forEach((fn) => {
        fn();
      });
      renderer.render(scene, camera);
    }

    render();

    return { scene, camera, clock };
  };

  // ================

  // Load character model and animations
  const loadCharacter = async (
    scene,
    characterFile,
    animationPath,
    animationFiles
  ) => {
    // Asset loader
    const gltfLoader = new GLTFLoader();

    function loadAsset(loader, assetPath, onLoad) {
      return new Promise((resolve) => {
        loader.load(assetPath, async (asset) => {
          if (onLoad[Symbol.toStringTag] === "AsyncFunction") {
            const result = await onLoad(asset);
            resolve(result);
          } else {
            resolve(onLoad(asset));
          }
        });
      });
    }

    // Load character model
    const { character, bindPoseOffset } = await loadAsset(
      gltfLoader,
      characterFile,
      (gltf) => {
        // Transform the character
        const character = gltf.scene;
        scene.add(character);

        // Make the offset pose additive
        const [bindPoseOffset] = gltf.animations;
        if (bindPoseOffset) {
          THREE.AnimationUtils.makeClipAdditive(bindPoseOffset);
        }

        // Cast shadows
        // character.traverse(object => {
        //   if (object.isMesh) object.castShadow = true;
        // });

        return { character, bindPoseOffset };
      }
    );

    // TODO: Intercept blendshape based animations here.
    // Load animations
    const clips = await Promise.all(
      animationFiles.map((filename) => {
        const filePath = `${animationPath}/${filename}`;
        return loadAsset(gltfLoader, filePath, async (gltf) => {
          return gltf.animations;
        });
      })
    );

    return { character, clips, bindPoseOffset };
  };

  // ================

  // Initialize the host
  const createHost = (
    character,
    audioAttachJoint,
    voice,
    engine,
    idleClip,
    faceIdleClip,
    lipsyncClips,
    gestureClips,
    gestureConfig,
    emoteClips,
    blinkClips,
    poiClips,
    poiConfig,
    lookJoint,
    bindPoseOffset,
    clock,
    camera,
    scene
  ) => {
    // Add the host to the render loop
    const host = new HOST.HostObject({ owner: character, clock });
    renderFn.push(() => {
      host.update();
    });

    // Set up text to speech
    const audioListener = new THREE.AudioListener();
    camera.add(audioListener);
    host.addFeature(HOST.aws.TextToSpeechFeature, false, {
      listener: audioListener,
      attachTo: audioAttachJoint,
      voice,
      engine,
    });

    listener.current = audioListener;

    // Set up animation
    host.addFeature(HOST.anim.AnimationFeature);

    // Base idle
    host.AnimationFeature.addLayer("Base");
    host.AnimationFeature.addAnimation(
      "Base",
      idleClip.name,
      HOST.anim.AnimationTypes.single,
      { clip: idleClip }
    );
    host.AnimationFeature.playAnimation("Base", idleClip.name);

    // Face idle
    host.AnimationFeature.addLayer("Face", {
      blendMode: HOST.anim.LayerBlendModes.Additive,
    });
    THREE.AnimationUtils.makeClipAdditive(faceIdleClip);
    host.AnimationFeature.addAnimation(
      "Face",
      faceIdleClip.name,
      HOST.anim.AnimationTypes.single,
      {
        clip: THREE.AnimationUtils.subclip(
          faceIdleClip,
          faceIdleClip.name,
          1,
          faceIdleClip.duration * 30,
          30
        ),
      }
    );
    host.AnimationFeature.playAnimation("Face", faceIdleClip.name);

    // Blink
    host.AnimationFeature.addLayer("Blink", {
      blendMode: HOST.anim.LayerBlendModes.Additive,
      transitionTime: 0.075,
    });
    blinkClips.forEach((clip) => {
      THREE.AnimationUtils.makeClipAdditive(clip);
    });
    host.AnimationFeature.addAnimation(
      "Blink",
      "blink",
      HOST.anim.AnimationTypes.randomAnimation,
      {
        playInterval: 3,
        subStateOptions: blinkClips.map((clip) => {
          return {
            name: clip.name,
            loopCount: 1,
            clip,
          };
        }),
      }
    );
    host.AnimationFeature.playAnimation("Blink", "blink");

    // Talking idle
    host.AnimationFeature.addLayer("Talk", {
      transitionTime: 0.75,
      blendMode: HOST.anim.LayerBlendModes.Additive,
    });
    host.AnimationFeature.setLayerWeight("Talk", 0);
    const talkClip = lipsyncClips.find((c) => c.name === "stand_talk");
    lipsyncClips.splice(lipsyncClips.indexOf(talkClip), 1);
    host.AnimationFeature.addAnimation(
      "Talk",
      talkClip.name,
      HOST.anim.AnimationTypes.single,
      { clip: THREE.AnimationUtils.makeClipAdditive(talkClip) }
    );
    host.AnimationFeature.playAnimation("Talk", talkClip.name);

    // Gesture animations
    host.AnimationFeature.addLayer("Gesture", {
      transitionTime: 0.5,
      blendMode: HOST.anim.LayerBlendModes.Additive,
    });

    gestureClips.forEach((clip) => {
      const { name } = clip;
      const config = gestureConfig[name];
      THREE.AnimationUtils.makeClipAdditive(clip);

      if (config !== undefined) {
        config.queueOptions.forEach((option) => {
          // Create a subclip for each range in queueOptions
          option.clip = THREE.AnimationUtils.subclip(
            clip,
            `${name}_${option.name}`,
            option.from,
            option.to,
            30
          );
        });
        host.AnimationFeature.addAnimation(
          "Gesture",
          name,
          HOST.anim.AnimationTypes.queue,
          config
        );
      } else {
        host.AnimationFeature.addAnimation(
          "Gesture",
          name,
          HOST.anim.AnimationTypes.single,
          { clip }
        );
      }
    });

    // Emote animations
    host.AnimationFeature.addLayer("Emote", {
      transitionTime: 0.5,
    });

    emoteClips.forEach((clip) => {
      const { name } = clip;
      host.AnimationFeature.addAnimation(
        "Emote",
        name,
        HOST.anim.AnimationTypes.single,
        { clip, loopCount: 1 }
      );
    });

    // Viseme poses
    host.AnimationFeature.addLayer("Viseme", {
      transitionTime: 0.12,
      blendMode: HOST.anim.LayerBlendModes.Additive,
    });
    host.AnimationFeature.setLayerWeight("Viseme", 0);

    /* TODO: I think this is where I can change things so that the blendStateOptions constant
    contains an array of animation clips that are all associated with turning a morphTarget on.
    Right now this method seems like my shortest path:

    https://threejs.org/docs/#api/en/animation/AnimationClip.CreateClipsFromMorphTargetSequences

    So. I need to just use Three to reach into the core character gltf file and extract a list of morph targets
    Then I need to filter that list so it only contains viseme morphTargets
    Then I'll see what that CreateClipsFromMorphTargetSequences method does with it.
    Then I should be able to feed that into the map function below.
    That might be the whole thing 🤞
    */

    // Slice off the reference frame
    const blendStateOptions = lipsyncClips.map((clip) => {
      THREE.AnimationUtils.makeClipAdditive(clip);
      return {
        name: clip.name,
        // clip: clip,
        clip: THREE.AnimationUtils.subclip(clip, clip.name, 1, 2, 30),
        weight: 0,
      };
    });

    host.AnimationFeature.addAnimation(
      "Viseme",
      "visemes",
      HOST.anim.AnimationTypes.freeBlend,
      { blendStateOptions }
    );
    host.AnimationFeature.playAnimation("Viseme", "visemes");

    // POI poses
    poiConfig.forEach((config) => {
      host.AnimationFeature.addLayer(config.name, {
        blendMode: HOST.anim.LayerBlendModes.Additive,
      });

      // Find each pose clip and make it additive
      config.blendStateOptions.forEach((clipConfig) => {
        const clip = poiClips.find((clip) => clip.name === clipConfig.clip);
        THREE.AnimationUtils.makeClipAdditive(clip);
        // clipConfig.clip = clip;
        clipConfig.clip = THREE.AnimationUtils.subclip(
          clip,
          clip.name,
          1,
          2,
          30
        );
      });

      host.AnimationFeature.addAnimation(
        config.name,
        config.animation,
        HOST.anim.AnimationTypes.blend2d,
        { ...config }
      );

      host.AnimationFeature.playAnimation(config.name, config.animation);

      // Find and store reference objects
      config.reference = character.getObjectByName(
        config.reference.replace(":", "")
      );
    });

    // Apply bindPoseOffset clip if it exists
    if (bindPoseOffset !== undefined) {

      host.AnimationFeature.addLayer("BindPoseOffset", {
        blendMode: HOST.anim.LayerBlendModes.Additive,
      });
      var subc = {
        clip: THREE.AnimationUtils.subclip(
          bindPoseOffset,
          bindPoseOffset.name,
          2,
          3,
          30
        ),
      };
      host.AnimationFeature.addAnimation(
        "BindPoseOffset",
        bindPoseOffset.name,
        HOST.anim.AnimationTypes.single,
        subc
      );
      host.AnimationFeature.playAnimation(
        "BindPoseOffset",
        bindPoseOffset.name
      );
    }

    // Set up Lipsync
    const visemeOptions = {
      layers: [{ name: "Viseme", animation: "visemes" }],
    };
    const talkingOptions = {
      layers: [
        {
          name: "Talk",
          animation: "stand_talk",
          blendTime: 0.75,
          easingFn: HOST.anim.Easing.Quadratic.InOut,
        },
      ],
    };
    host.addFeature(HOST.LipsyncFeature, false, visemeOptions, talkingOptions);

    // Set up Gestures
    host.addFeature(HOST.GestureFeature, false, {
      layers: {
        Gesture: { minimumInterval: 3 },
        Emote: {
          blendTime: 0.5,
          easingFn: HOST.anim.Easing.Quadratic.InOut,
        },
      },
    });

    // Set up Point of Interest
    host.addFeature(
      HOST.PointOfInterestFeature,
      false,
      {
        target: camera,
        lookTracker: lookJoint,
        scene,
      },
      {
        layers: poiConfig,
      },
      {
        layers: [{ name: "Blink" }],
      }
    );

    return host;
  };

  // ====================================

  return (
    <div id="three-div">
      <LoaderDiv />
      <SpeechWindow
        speechWindowText={speechWindowText}
        setSpeechWindowText={setSpeechWindowText}
      />
      <div id="renderCanvas" />
    </div>
  );
}

export default ThreeDiv;
