import React, {useContext, useEffect, useRef, useState} from "react";
import * as faceapi from '@vladmandic/face-api';
import {
  getCurrentFaceDetectionNet,
  getFaceDetectorOptions,
  isFaceDetectionModelLoaded,
  TINY_FACE_DETECTOR
} from "../helpers/detectionControls";
import * as cocoSsd from "@tensorflow-models/coco-ssd";
import conf from "../../config";
import {useSelector} from "react-redux";
import {selectConfig} from "../../slices/config";
import {EVENT_PRIORITY, EVENT_TYPES} from "../../constants/events";
import {EventsContext} from "../../eventsQueue";


const BrowserDetection = (props) => {
  const {
    videoRef,
    isReady,
    faceDetectionOptions,
    objectsDetectionOptions,
    objectsTimeoutsRef,
    expressionSelectedRef,
    expressionTimeoutRef,
  } = props
  const { EXPRESSIONS, OBJECTS } = conf.constants;
  const canvasRef = useRef(null);
  const { createEvent, pushToQueue } = useContext(EventsContext)
  const cocoSsdModel = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [faceDetector, setFaceDetector] = useState(TINY_FACE_DETECTOR);
  const faceDetectorPublicPath = '/fd';
  const expressionsRoutineRef = useRef();
  const objectsRoutineRef = useRef();
  const configRef = useRef({});
  const config = useSelector(selectConfig);

  useEffect(() => {
    configRef.current = {...config};

    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    context.clearRect(0, 0, canvas.width, canvas.height);
  }, [config]);

  useEffect(() => {
    if (!isReady) {
      cleanUp()
      return
    }
    onPlay()
    // eslint-disable-next-line
  }, [isReady])

  const onDetect = (type, value) => {
    pushToQueue(createEvent({
      type,
      data: {
        value,
      },
      weight: EVENT_PRIORITY.MEDIUM,
      // expireDate: Date.now() + 30 * 1000,
    }))
  }

  const acceptableExpressionLevel = faceDetectionOptions?.level || 0.9;
  const acceptableExpressionDelay = faceDetectionOptions?.delay || 2 * 1000; // 2 sec
  const acceptableExpressionsWhitelist = faceDetectionOptions?.whitelist || {
    [EXPRESSIONS.HAPPY]: 1,
    [EXPRESSIONS.SURPRISED]: 1
  };

  const acceptableObjectsLevel = objectsDetectionOptions?.level || 0.5;
  const acceptableObjectsDelay = objectsDetectionOptions?.delay || 2 * 1000; // 2 sec
  const acceptableObjectsWhitelist = objectsDetectionOptions?.whitelist || {
    [OBJECTS.BANANA]: 1,
    [OBJECTS.CELL_PHONE]: 1,
    [OBJECTS.BOOK]: 1,
    [OBJECTS.PERSON]: 1,
  };

  useEffect(() => {
    startUp()
    return () => {
      cleanUp()
    }
    // eslint-disable-next-line
  }, []);

  const cleanUp = () => {
    clearInterval(expressionsRoutineRef.current)
    clearInterval(objectsRoutineRef.current)
    clearTimeout(expressionTimeoutRef.current);
  }

  const startUp = async () => {
    // Load face detector & expressions detector
    if (!isFaceDetectionModelLoaded(faceDetector)) {
      await getCurrentFaceDetectionNet(faceDetector).load(faceDetectorPublicPath)
    }
    await faceapi.loadFaceExpressionModel(faceDetectorPublicPath);
    // Load objects detector
    cocoSsdModel.current = await cocoSsd.load('lite_mobilenet_v2');
    console.log('cocoSsdModel.current', cocoSsdModel.current)
  }

  const startDetectObjects = async (videoEl, canvas, ctx) => {
    if (cocoSsdModel.current) {
      console.log('detection objects');
      const objectsDetectionResult = await cocoSsdModel.current.detect(videoRef.current);

      onObjectsDetectionResult(objectsDetectionResult);
      if (configRef.current.isObjectsBoxEnabled) {
        drawCanvasObjectsBoxes(videoEl, canvas, ctx, objectsDetectionResult);
      }
    }
  };

  const drawCanvasEmotionsBoxes = (canvas, videoEl, results) => {
    try {
      const dims = faceapi.matchDimensions(canvas, videoEl, true);

      const resizedResult = faceapi.resizeResults(results, dims);
      const minConfidence = 0.05;
      faceapi.draw.drawDetections(canvas, resizedResult);
      faceapi.draw.drawFaceExpressions(canvas, resizedResult, minConfidence)
    } catch (e) { console.warn(e.message) }
  };

  const onPlay = async () => {
    const videoEl = videoRef.current;
    if (!videoEl) return setTimeout(() => onPlay());

    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    if (videoEl.paused || videoEl.ended) return setTimeout(() => onPlay());

    expressionsRoutineRef.current = setInterval(() => startDetectExpressions(videoEl, canvas, context), 1000);
    objectsRoutineRef.current = setInterval(() => startDetectObjects(videoEl, canvas, context), 1000);
  }

  const onExpressionDetectionResult = (result) => {
    if (!(result && result.expressions)) return null;

    const expressions = result.expressions;
    const mostValuableExpression = Object.keys(expressions).reduce((a, b) => (expressions[a] > expressions[b]) ? a : b);

    if (expressions[mostValuableExpression] >= acceptableExpressionLevel &&
      mostValuableExpression !== expressionSelectedRef.current) {
      expressionSelectedRef.current = mostValuableExpression;
      clearTimeout(expressionTimeoutRef.current);
      expressionTimeoutRef.current = setTimeout(() => {
        if (expressionSelectedRef.current !== mostValuableExpression) return null;
        if (acceptableExpressionsWhitelist[expressionSelectedRef.current] > 0) {
          onDetect(EVENT_TYPES.EXPRESSION_DETECTED, expressionSelectedRef.current);
          acceptableExpressionsWhitelist[expressionSelectedRef.current] -= 1
        }
        expressionSelectedRef.current = null
      }, acceptableExpressionDelay)
    }
  };

  const onObjectsDetectionResult = (results) => {
    if (!(results && results.length)) return null;
    const filteredResults = results.filter(r => Object.values(OBJECTS).includes(r.class) && r.score >= acceptableObjectsLevel);
    filteredResults.forEach(result => {
      const sendDetected = (object) => {
        return () => {
          if (acceptableObjectsWhitelist[object] > 0) {
            onDetect(EVENT_TYPES.OBJECT_DETECTED, object);
            acceptableObjectsWhitelist[object] -= 1
          }
          objectsTimeoutsRef.current[object] = 0
        };
      };
      if (!objectsTimeoutsRef.current[result.class]) {
        objectsTimeoutsRef.current[result.class] = setTimeout(sendDetected(result.class), acceptableObjectsDelay);
      }
    })
  };

  const drawCanvasObjectsBoxes = (videoEl, canvas, ctx, results) => {
    try {
      results.forEach((i) => {
        const [x, y, width, height] = i.bbox;
        const thing = i.class;
        let score = i.score;

        score = Math.round(score * 100) / 100;

        ctx.beginPath();
        ctx.moveTo(x, y);
        ctx.lineTo(x + width, y);
        ctx.lineTo(x + width, y + height);
        ctx.lineTo(x, y + height);
        ctx.lineTo(x, y);
        ctx.stroke();

        ctx.fillStyle = "#ffffff";
        ctx.fillRect(x, y - 20, width, 20);

        ctx.fillStyle = "black";
        ctx.font = "12px PT Mono";
        ctx.fillText(thing + " (" + score + ")", x, y - 10);
      });

      setTimeout(() => {
        ctx.clearRect(0, 0, canvas.width, canvas.height)
      }, 1);
    } catch (e) { console.warn(e.message) }
  };

  const startDetectExpressions = async (videoEl, canvas, ctx) => {
    if (isFaceDetectionModelLoaded(faceDetector)) {
      console.log('detection face');
      const options = getFaceDetectorOptions(faceDetector);

      const emotionsDetectionResult = await faceapi.detectSingleFace(videoEl, options).withFaceExpressions();
      onExpressionDetectionResult(emotionsDetectionResult);

      if (configRef.current.isEmotionsBoxEnabled) drawCanvasEmotionsBoxes(canvas, videoEl, emotionsDetectionResult)
    }
  };

  return (
    <canvas ref={canvasRef} className={`video-webcam ${isReady ? '' : 'disabled'}`} id="overlay"/>
  )
}

export default BrowserDetection
