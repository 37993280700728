import React from "react";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

const LogOut = () => {
  const push = () => {
    window.location.href = `${window.location.origin}/login`;
  };

  const runCallback = (cb) => {
    return cb();
  };

  return (
    <div className="logout-component">
      <div className="logout-box">
        {runCallback(() => {
          const row = [];
          for (var i = 0; i < 25; i++) {
            row.push(<div key={i} className="dot" />);
          }
          return row;
        })}
      </div>
      <div className="logout-div">
        <div className="logout-block">
          <div className="logout-logo">
            <img src="/images/Logo.png" alt="Verbato logo" />
            <Typography mt={2}>Closed Alpha</Typography>
          </div>
        </div>
        <div className="logout-block">
          <div className="logout-btn" onClick={() => push()}>
            <Typography mt={2}>
              Sign in
            </Typography>
          </div>
        </div>
        <div className="logout-block">
          <Typography mt={2}>
            Or <Link to="#">Sign up</Link>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default LogOut;
