import CharacterPromptModal from "./CharacterPromptModal/CharacterPromptModal";


const ModalsContainer = () => {
  return (
    <>
      <CharacterPromptModal />
    </>
  )
}


export default ModalsContainer
