import { configureStore } from '@reduxjs/toolkit'
import configReducer from "./slices/config";
import scenarioReducer from "./slices/scenario";
import socketReducer from "./slices/socket";
import modalsReducer from "./slices/modals";
import chatReducer from "./slices/chat";

export const store = configureStore({
  reducer: {
    config: configReducer,
    scenario: scenarioReducer,
    socket: socketReducer,
    modals: modalsReducer,
    chat: chatReducer,
  },
});
