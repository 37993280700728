import {modalStyles} from "../styles";


export const styles = {
  wrapper: {
    ...modalStyles.wrapper,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
  },
  textArea: {
    width: '100%',
    margin: '10px 0',
    backgroundColor: '#f3f3f3',
  },
}
