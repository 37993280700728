import React, { useEffect, useState, useRef, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import ChatIcon from "@mui/icons-material/Chat";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import { Typography } from "@mui/material";

import InputChat from "./helpers/InputChat.js";
import LocalPop from "./helpers/LocalPop.js";
import { isAM } from "./helpers/chatUtils";

import { onCompleteScenarioStep, selectScenario } from "../slices/scenario";
import {
  cleanChat,
  selectChatLog,
  selectEnteredMessage,
  sendToChat,
  setEnteredMessage,
} from "../slices/chat";

import { isScenarioCharacter, onScenarioHandler } from "../config/scenarios";
import { SPEECH } from "../config/constants";
import { selectConfig, setRecognizedText } from "../slices/config";
import { EventsContext } from "../eventsQueue";
import { EVENT_PRIORITY, EVENT_TYPES } from "../constants/events";
import { sendCompletions } from '../http';
import conf from "../config/index";

function Chat({ refresh }) {
  const dispatch = useDispatch();
  const scenario = useSelector(selectScenario);
  const chatLog = useSelector(selectChatLog);
  const { recognizedText, characterPrompt, modelAddress, gptTemperature, chatActive } =
    useSelector(selectConfig);
  const enteredMessage = useSelector(selectEnteredMessage);
  const { createEvent, pushToQueue } = useContext(EventsContext);

  const [localMessage, setLocalMessage] = useState("");
  const [computeTime, setComputeTime] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleTts = (data) => {
    pushToQueue(
      createEvent({
        type: EVENT_TYPES.TTS_PROCESSING,
        data,
        weight: EVENT_PRIORITY.HIGHEST,
      })
    );
  };

  // Helper for sending recognized text
  useEffect(() => {
    if (
      chatLog.length &&
      typeof recognizedText === "string" &&
      recognizedText !== "" &&
      chatLog[chatLog.length - 1].local
    ) {
      if (isAM(recognizedText)) {
        dispatch(setEnteredMessage(""));
        dispatch(setRecognizedText(""));
        return;
      }

      const isScenario = isScenarioCharacter();
      if (isScenario) {
        const { stepId, scenarioText } = onScenarioHandler(
          scenario,
          SPEECH,
          recognizedText
        );
        if (stepId) dispatch(onCompleteScenarioStep(stepId));

        if (scenarioText) {
          setIsLoading(true);
          setTimeout(() => {
            setIsLoading(false);
            handleTts({
              speech: scenarioText.speech,
              value: scenarioText.original,
            });
          }, 1000);
          dispatch(setEnteredMessage(""));
          dispatch(setRecognizedText("")); // cleaning recognized text hook
        }
      } else {
        setIsLoading(true);
        sendData(recognizedText);
        dispatch(setEnteredMessage(""));
        dispatch(setRecognizedText("")); // cleaning recognized text hook
      }
    }
    // eslint-disable-next-line
  }, [chatLog]);

  useEffect(() => {
    if (characterPrompt && chatLog.length) {
      dispatch(cleanChat());
    }
    // eslint-disable-next-line
  }, [characterPrompt]);

  // Scroll to the end of the recognized text message
  const voicetextEndRef = useRef(null);
  const scrollToBottom = () => {
    voicetextEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    scrollToBottom();
  }, [recognizedText]);

  const messagesEndRef = useRef(null);

  const scrollToEndMessage = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToEndMessage();
  }, [chatLog.length]);

  useEffect(() => {
    cleaningFunc();
    // eslint-disable-next-line
  }, [refresh]);

  const submitHandler = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (localMessage === "") {
      return;
    }
    setIsLoading(true);
    // console.log('==Submit==>', localMessage);
    dispatch(sendToChat({ message: localMessage, local: true })); // Save output message in chat log

    sendData(localMessage);
    setLocalMessage("");
  };

  const cleaningFunc = () => {
    dispatch(cleanChat());
    setLocalMessage("");
    setIsLoading(false);
    dispatch(setRecognizedText([]));
  };

  const genereateReq = (history) => {
    let str = `\nAI: ${characterPrompt}`;
    history.forEach((i) => {
      if (!i.local) {
        return (str = str + `\nAI:${i.message}`);
      }
      return (str = str + `\nHuman:${i.message}`);
    });
    str = str + `\nAI:`;
    return {
      prompt: str,
      temperature: gptTemperature,
      max_tokens: 150,
      top_p: 1,
      frequency_penalty: 0.0,
      presence_penalty: 0.6,
      stop: [" Human:", " AI:"],
      characterName: conf.getSelectedCharacter(),
    };
  };

  const sendData = async (recognizedText) => {
    if (!recognizedText || (localMessage === "" && recognizedText === "")) {
      setIsLoading(false);
      return;
    }
    try {
      const startTime = new Date().getTime();

      const response = await sendCompletions(modelAddress, genereateReq, chatLog, recognizedText);
      const endTime = new Date().getTime();

      setComputeTime(Number((endTime - startTime) / 1000).toFixed(2) + " sec");


      // Console group for OpenAI or Cache event time mark
      console.group(
        response.data.model === "cache" ? '%c 🪣 Cache event' : "%c 🤖 OpenAI event",
        "background: #222; color: #bada55; padding: 2px; border-radius: 3px; line-height: 20px;"
      );
      console.log(
        "%c Event started: ",
        "color: green; font-weight: bold;",
        new Date(startTime).toTimeString()
      );
      console.log(
        "%c Total time: ",
        "color: blue; font-weight: bold;",
        ((endTime - startTime) / 1000).toFixed(3) + " sec"
      );
      console.groupEnd();

      if (response.status === 200) {
        // Received data from api and saved to hooks
        const { choices } = response.data;
        handleTts({
          value: choices[0].text,
        });
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <nav className={chatActive ? "chat active" : "chat"} id="chat-div">
      <div className="chat-hamburger" id="chat-open-div">
        {chatActive ? <></> : <ChatIcon fontSize="medium" />}
      </div>
      <div className="chat-header">
        <div className="chat-hamburger" id="chat-close-div">
          {chatActive ? (
            <KeyboardTabIcon
              fontSize="medium"
              id="chat-open"
            />
          ) : (
            <></>
          )}
        </div>
        <Typography variant="h6" component="h6">
          Closed Caption Log
        </Typography>
        <div className="chat-progress">
          {isLoading ? (
            <></>
          ) : (
            <>
              {computeTime === "" ? (
                <></>
              ) : (
                <LocalPop computeTime={computeTime} />
              )}
            </>
          )}
        </div>
      </div>
      <div className="chat-content">
        <form className="tts-form">
          <div id="chat" className="chat-window">
            {chatLog.map((value, key) => {
              if (value.local === true) {
                return (
                  <p key={key} className="local-message">
                    {value.message}
                  </p>
                );
              } else {
                return (
                  <p key={key} className="remote-message">
                    {value.message}
                  </p>
                );
              }
            })}

            {isLoading ? (
              <div className="message-typing">
                <div className="typing typing-1" />
                <div className="typing typing-2" />
                <div className="typing typing-3" />
              </div>
            ) : (
              <></>
            )}
            <div ref={messagesEndRef} />
          </div>
        </form>
        <InputChat
          submitHandler={submitHandler}
          setLocalMessage={setLocalMessage}
          cleaningFunc={cleaningFunc}
          chatLen={chatLog.length}
          enteredMessage={enteredMessage}
          localMessage={localMessage}
        />
      </div>
    </nav>
  );
}
export default Chat;
