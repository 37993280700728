import React, {useEffect} from 'react';
import { useKeycloak } from '@react-keycloak/web'
import "./sass/App.scss";

import Main from "./components/Main.js";
import SignIn from "./components/helpers/SignIn.js";
import LogOut from "./components/helpers/LogOut.js";
import LoaderDiv from "./components/helpers/LoaderDiv";
import CharSelection from "./components/helpers/CharSelect.js";
import SignCallback from "./components/helpers/SignCallback.js";
import { SocketIO } from "./socket";
import ModalsContainer from "./components/Modals";
import EventsQueue from "./eventsQueue";

import {
    BrowserRouter,
    useRoutes,
    Navigate, useNavigate,
} from 'react-router-dom';
import WithoutAccess from './components/pages/WithoutAccess';

const RoutesWrapper = () => {

    const { keycloak } = useKeycloak()
    const navigate = useNavigate();
    useEffect(() => {
        // user have only basic role that mean he cant get access to resource before aproving by admin
        if(keycloak && keycloak.resourceAccess && Object.keys(keycloak.resourceAccess).length === 1){
            navigate('/welcome')
        }
    }, [keycloak])

    const routing = useRoutes(
        [
          {
            path: "/",
            element: keycloak.authenticated ? <Navigate to="/app" /> : <Navigate to="/login" />
          },
          {
            path: "/login",
            element: <SignIn />
          },
          {
            path: "/logout",
            element: <LogOut />
          },

            {
                path: '/welcome',
                element: <WithoutAccess></WithoutAccess>

            },
          {
            path: "/callback",
            element: <SignCallback />
          },
          {
            path: "/app",
            element: keycloak.authenticated ? (
              <SocketIO>
                <EventsQueue>
                  <Main />
                  <ModalsContainer />
                </EventsQueue>
              </SocketIO>
            ) : (
              <Navigate to="/login" />
            )
          },
          {
            path: "/app/char",
            element: keycloak.authenticated ? (<CharSelection />) : (<Navigate to="/login" />)
          },
        ]
      );
  return routing;
};

const App = () =>  {
    const { initialized } = useKeycloak()

    if (!initialized) {
        return (<LoaderDiv />)
    }

  return (
    <div className="App">
      <BrowserRouter>
        <RoutesWrapper />
      </BrowserRouter>
    </div>
  );
}

export default App;
