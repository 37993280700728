import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SendIcon from "@mui/icons-material/Send";

import styles from "./MakeStyles.js";

export default function CustomizedInputBase({
  chatLen,
  submitHandler,
  setLocalMessage,
  localMessage,
  cleaningFunc,
  enteredMessage,
}) {
  const classes = styles.inputStyles();
  const chatIn = document.getElementById("chat-input-full");

  useEffect(() => { // Following by input coursor by the last symbol in STT results
    if (chatIn && typeof(enteredMessage) === "string") {
      chatIn.focus();
      // chatIn.setSelectionRange(recognizedText.length, recognizedText.length);
      chatIn.scrollTo(500, 0);
    }
    // eslint-disable-next-line
  }, [enteredMessage])


  return (
    <div className="chat-input">
      <Paper component="form" className={classes.root}>
        <IconButton
          className={classes.iconButton}
          onClick={() => cleaningFunc()}
          size="large">
          {chatLen === 0 ? (
            <DeleteForeverIcon />
          ) : (
            <DeleteForeverIcon color="secondary" />
          )}
        </IconButton>
        <Divider className={classes.divider} orientation="vertical" />
        {!enteredMessage.length ? (
          <InputBase
            className={classes.input}
            placeholder="Enter the message..."
            inputProps={{ "aria-label": "send message" }}
            onChange={(e) => setLocalMessage(e.target.value)}
            value={localMessage}
          />
        ) : (
          <InputBase
            id="chat-input-full"
            className={classes.input}
            inputProps={{ "aria-label": "send message" }}
            value={enteredMessage}
          />
        )}
        <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label="search"
          onClick={(e) => submitHandler(e)}
          size="large">
          {!enteredMessage.length ? (
            <SendIcon color="primary" />
          ) : (
            <SendIcon color="action" />
          )}
        </IconButton>
      </Paper>
    </div>
  );
}
