export const EVENT_TYPES = {
  OBJECT_DETECTED: 'OBJECT_DETECTED',
  EXPRESSION_DETECTED: 'EXPRESSION_DETECTED',
  TTS_PROCESSING: 'TTS_PROCESSING',
}

export const EVENT_PRIORITY = {
  LOW: 1000,
  MEDIUM: 2000,
  HIGH: 3000,
  HIGHEST: 4000,
}
