import { createSlice } from '@reduxjs/toolkit'
import {CHARACTER_PRESETS} from "../config/constants";
import {getDetectorOrigin, getSelectedCharacter, getCurrentVoice} from "../config/sceneConfig";

const name = 'config'

const initialState = {
  sttEnabled: false,
  characterPrompt: localStorage.getItem('promptset') || CHARACTER_PRESETS[getSelectedCharacter()],
  detectionOrigin: getDetectorOrigin(),
  languageCode: "en-US",
  neuralEngine: "neural",
  voice: localStorage.getItem('chr-voice') || getCurrentVoice(),
  speechWindow: false,
  gesturesEnabled: true,
  modelAddress: "text-curie-001",
  ttsText: '',
  recognizedText: '',
  sttTimeout: 1.0,
  sttSensitivity: 0.1,
  gptTemperature: 0.9,
  isEmotionsBoxEnabled: false,
  isObjectsBoxEnabled: false,
  chatActive: false,
  sideBarActive: false,
};

export const configSlice = createSlice({
  name,
  initialState,
  reducers: {
    changeStt: (state, action) => {
      state.sttEnabled = action.payload
    },
    setIsEmotionsBoxEnabled: (state, action) => {
      state.isEmotionsBoxEnabled = action.payload
    },
    setIsObjectsBoxEnabled: (state, action) => {
      state.isObjectsBoxEnabled = action.payload
    },
    setCharacterPrompt: (state, action) => {
      state.characterPrompt = action.payload
    },
    setDetectionOrigin: (state, action) => {
      state.detectionOrigin = action.payload
    },
    setLanguageCode: (state, action) => {
      state.languageCode = action.payload
    },
    setNeuralEngine: (state, action) => {
      state.neuralEngine = action.payload
    },
    setVoice: (state, action) => {
      state.voice = action.payload;
      localStorage.setItem('chr-voice', action.payload);
      window.location.reload();
    },
    setSpeechWindow: (state, action) => {
      state.speechWindow = action.payload
    },
    setGesturesEnabled: (state, action) => {
      state.gesturesEnabled = action.payload
    },
    setModelAddress: (state, action) => {
      state.modelAddress = action.payload
    },
    setTtsText: (state, action) => {
      state.ttsText = action.payload
    },
    setRecognizedText: (state, action) => {
      state.recognizedText = action.payload
    },
    setSttTimeout: (state, action) => {
      state.sttTimeout = action.payload
    },
    setSttSensitivity: (state, action) => {
      state.sttSensitivity = action.payload
    },
    setGptTemperature: (state, action) => {
      state.gptTemperature = action.payload
    },
    setChatActive: (state, action) => {
      state.chatActive = action.payload
    },
    setSideBarActive: (state, action) => {
      state.sideBarActive = action.payload
    },
  },
});

export const {
  changeStt,
  setIsEmotionsBoxEnabled,
  setIsObjectsBoxEnabled,
  setCharacterPrompt,
  setDetectionOrigin,
  setLanguageCode,
  setNeuralEngine,
  setVoice,
  setSpeechWindow,
  setGesturesEnabled,
  setModelAddress,
  setTtsText,
  setRecognizedText,
  setSttTimeout,
  setSttSensitivity,
  setGptTemperature,
  setChatActive,
  setSideBarActive,
} = configSlice.actions;

export const enableStt = (value) => (dispatch) => {
  dispatch(changeStt(value))
};

export const enableEmotionsDetectionBox = (value) => (dispatch) => {
  dispatch(setIsEmotionsBoxEnabled(value))
};

export const enableObjectsDetectionBox = (value) => (dispatch) => {
  dispatch(setIsObjectsBoxEnabled(value))
};

export const changeDetectionOrigin = (value) => dispatch => {
  if (localStorage.getItem('det-orig') !== value) {
    localStorage.setItem('det-orig', value)
  }
  dispatch(setDetectionOrigin(value))
}

export const selectConfig = state => state[name]
export const selectCharacterPrompt = state => state[name].characterPrompt
export const selectDetectionOrigin = state => state[name].detectionOrigin
export const selectLanguageCode = state => state[name].languageCode
export const selectNeuralEngine = state => state[name].neuralEngine
export const selectVoice = state => state[name].voice
export const selectSpeechWindow = state => state[name].speechWindow
export const selectGesturesEnabled = state => state[name].gesturesEnabled
export const selectModelAddress = state => state[name].modelAddress
export const selectTtsText = state => state[name].ttsText
export const selectRecognizedText = state => state[name].recognizedText
export const selectSttTimeout = state => state[name].sttTimeout
export const selectSttSensitivity = state => state[name].sttSensitivity
export const selectGptTemperature = state => state[name].gptTemperature
export const selectChatActive = state => state[name].chatActive
export const selectSideBarActive = state => state[name].sideBarActive

export default configSlice.reducer
