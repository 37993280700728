import React, { useState } from "react";

import Header from "./Header.js";
import Sidebar from "./Sidebar.js";
import Chat from "./Chat.js";
import Content from "./Content.js";

function Main() {
  const [refresh, setRefresh] = useState(false);

  return (
    <div>
      <Header />
      <div className="main-body">
        <Sidebar
          setRefresh={setRefresh}
        />

        <Content />

        <Chat
          refresh={refresh}
        />
      </div>
    </div>
  );
}

export default Main;
