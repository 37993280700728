import { createContext, useEffect } from 'react'
import { io } from 'socket.io-client'
import { SocketMsgQueue } from './SocketMsgQueue'
import {useDispatch} from "react-redux";
import { setIsSocketConnected } from "../slices/socket";

const sender = new SocketMsgQueue()

export const SocketContext = createContext(sender)

export const SocketIO = ({ children }) => {
  const dispatch = useDispatch()
  function _createSocket() {
    const access_token = localStorage.getItem('access_token')
    const socket = io(process.env.REACT_APP_SOCKET_URL, {
      auth: {
        token: access_token
      },
      path: '/socket.io',
      transports: ['websocket', 'polling'],
    })

    sender.socket = socket

    socket.on('connect', () => {
      dispatch(setIsSocketConnected(true))
      console.log('connected')
    })

    socket.on('disconnect', () => {
      dispatch(setIsSocketConnected(false))
      console.log('disconnected')
    })
  }

  useEffect(() => {
      if (!sender.connected) {
        _createSocket()
      } else {
        sender.disconnect()
      }
  // eslint-disable-next-line
  }, []);

  return (
    <SocketContext.Provider value={sender}>
      {children}
    </SocketContext.Provider>
  )
}
