export const CHARACTERS = {
  TORI: 'TORI',
  TOM: 'TOM',
  ZOLTAR: 'ZOLTAR',
  FREDDY: 'FREDDY',
  CHANI: 'CHANI'
};

export const EXPRESSION = 'expression';
export const OBJECT = 'object';
export const SPEECH = 'speech';
export const UNEXPECTED = 'unexpected';

export const EXPRESSIONS = {
  ANGRY: 'angry',
  DISGUSTED: 'disgusted',
  FEARFUL: 'fearful',
  HAPPY: 'happy',
  NEUTRAL: 'neutral',
  SAD: 'sad',
  SURPRISED: 'surprised'
};

export const OBJECTS = {
  PERSON: 'person',
  BOOK: 'book',
  CELL_PHONE: 'cell phone',
  BANANA: 'banana'
};

export const EXPRESSIONS_VALUES = {
  [EXPRESSIONS.SURPRISED]: [
    'Whoa, why do you look surprised?',
    'I’m sorry, did I say something wrong?',
    'You look surprised, what’s up?',
    'Wow, I didn’t mean to startle you',
  ],
  [EXPRESSIONS.HAPPY]: [
    'aww, that’s such a nice smile',
    'You should smile more',
    'I like that smile',
    'It’s nice to see you smile',
  ]
};

export const OBJECTS_VALUES = {
  [OBJECTS.CELL_PHONE]: [
    'Nice phone!',
    'Nice, what model is that?',
    'Is someone calling you or are you just showing off?',
    'Oh cool, I like your phone',
  ],
  [OBJECTS.BANANA]: [
    'Bon appetit! I love bananas.',
    'Nice. Did you know bananas originated from the Malay archipelago in southeast asia?',
    'Oh, cool, a banana',
    'Banana. Nice. Are you going to eat that now?',
  ]
};

export const CHARACTER_PRESETS = {
  [CHARACTERS.TORI]: 'My name is Tory. I\'m woman. I\'m living in Sacramento. I\'m not a bot. My favourite hobby is football. I\'m crazy about Justin Timberlake. My profession is bodyguard for Ariana Grande.',
  [CHARACTERS.TOM]: 'I\'m Tom Brady. I play for the Tampa Bay Buccaneers. The only other team I played for was the New England Patriots for 20 years. I don\'t talk about my personal life. I don\'t talk about Bruce Ariens\' retirement and Bruce Ariens\' leaving the team. I don\'t want to talk about deflate-gate. I am happy to talk about football, golf, and basketball. I don\'t talk about porn, sex, and inappropriate content.',
  [CHARACTERS.ZOLTAR]: 'My name is Zoltar. I\'m man. I\'m living in world of magic. My favourite hobby is reading. I\'m crazy about David Blane. I can see the future and make guesses about the future of any person.',
  [CHARACTERS.CHANI]: 'The following is a conversation between the spirit of Chani Kynes and a human. Chani\'s location is the desert of Arrakis. Chani died during childbirth, the kids were the twins of Paul Aterides. Chani needs you to find Paul and make sure the twins are safe.',
  [CHARACTERS.FREDDY]: '',
};

export const DETECTION_ORIGIN = {
  SERVER: 'server',
  BROWSER: 'browser',
}

export const CHARIMGS = [
  { path: "zoltar-m.png", name: "Zoltar" },
  { path: "tom-m.png", name: "Tom" },
  { path: "chani-m.png", name: "Chani" },
  { path: "TORY.gif", name: "Tori" },
  { path: "freddy-m.png", name: "Freddy" },
  { path: "new-m.png", name: "Add new", new: true },
];
