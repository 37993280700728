import { createSlice } from '@reduxjs/toolkit'
import config from '../config'
import { getSelectedCharacter } from "../config/sceneConfig";

const initialState = {
 scenario: config.loadScenario(getSelectedCharacter())
};

export const scenarioSlice = createSlice({
  name: 'scenario',
  initialState,
  reducers: {
    completeScenarioStep: (state, action) => {
      state.scenario = [...state.scenario].filter(s => s.id !== action.payload);
    },
  },
});

export const {
  completeScenarioStep,
} = scenarioSlice.actions;

export const onCompleteScenarioStep = (id) => (dispatch) => {
  dispatch(completeScenarioStep(id))
};

export const selectScenario = state => {
  return state.scenario.scenario
};

export default scenarioSlice.reducer